import React from 'react';
import { Box, Chip, Link, useTheme } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { useIsLoading } from 'components/Clients/clientHooks';
import { SkeletonCustom } from 'components/SkeletonCustom/SkeletonCustom';
import { getRoute, routes } from 'lib/routes';
// import 'components/JsonEditor/jsonEditorDark.css';

/**
 *
 * @param {Object} props
 * @param {import('lib/client/clientService').ClientService} props.service
 * @param {string} props.envId
 */
export const ServicesListItem = (props) => {
  const isLoading = useIsLoading();
  const theme = useTheme();
  const status = props.service.getStatus();
  const statusChipLabel = (() => {
    if (!status) return;
    if (props.service.isWeb()) {
      if (!status.branch || !status.commit) return;
      return `${status.branch}/${status.commit}`;
    } else if (props.service.isApi()) {
      if (!status.version) return;
      return status.version;
    }
  })();
  const statusColor = (() => {
    if (!status || status.status === 'unknown') return theme.palette.grey['400'];
    if (status.status === 'online') return theme.palette.success.light;
    if (status.status === 'offline') return theme.palette.error.light;
  })();

  return (
    <SkeletonCustom isActive={isLoading}>
      <Box marginBottom={1} border={1} borderColor={theme.palette.divider} borderRadius="undefinedpx">
        <Box borderLeft={6} borderColor={statusColor} borderRadius="undefinedpx">
          <Box padding={1} display="flex" alignItems="center">
            <Link
              variant="body2"
              component={LinkRouter}
              to={getRoute(routes.clientService, {
                clientId: props.service.getClientEnv().getClient().getId(),
                envId: props.envId,
                serviceId: props.service.getId(),
              })}
              color="inherit"
              style={{ flexGrow: 1 }}
            >
              {props.service.getName()}
            </Link>

            {statusChipLabel && (
              <Box style={{ overflowX: 'scroll' }} fontFamily="Monospace">
                <Chip label={statusChipLabel} style={{ marginLeft: theme.spacing(1) }} />
              </Box>
            )}
          </Box>
          {/* <Box padding={2} style={{ backgroundColor: theme.palette.grey['200'] }}>
            {props.service.isCustomData() && (
              <Box height={1} width={1} display="flex" justifyContent="center">
                {clientEnv.getEnvConfigId() ? (
                  <ConfigEditor
                    envConfigId={clientEnv.getEnvConfigId()}
                    editorProps={{
                      mode: 'view',
                    }}
                  />
                ) : (
                  <Typography color="textSecondary">Config is empty</Typography>
                )}
              </Box>
            )}
          </Box> */}
        </Box>
      </Box>

      {/* <Box width={1} display="flex" alignItems="center" marginBottom={1}>
        <Typography display="inline" style={{ fontWeight: theme.typography.fontWeightBold }}>
          {props.service.getName()}
        </Typography>
        <Box flexGrow={1} />
        {clientService && (
          <Button
            component={LinkRouter}
            to={getRoute(routes.clientService, {
              clientId: client.getId(),
              serviceId: clientService.getId() || '',
              envId,
            })}
            // color="textSecondary"
            style={{ marginLeft: theme.spacing(2) }}
          >
            Configure
          </Button>
        )}
      </Box>
      <Box
        elevation={0}
        display="flex"
        component={Paper}
        // height={50}
        padding={2}
        border={1}
        borderRadius={theme.shape.borderRadius}
        borderColor={theme.palette.grey['400']}
        marginBottom={1}
        alignItems="center"
        className={classNames.product}
        style={{ cursor: 'pointer' }}
      >
        <Box height={1} width={1} display="flex" justifyContent="center">
          {clientService ? (
            <>
              {props.service.isCustomData() && (
                <Box height={1} width={1} display="flex" justifyContent="center">
                  {clientEnv.getEnvConfigId() ? (
                    <ConfigEditor
                      envConfigId={clientEnv.getEnvConfigId()}
                      editorProps={{
                        mode: 'view',
                      }}
                    />
                  ) : (
                    <Typography color="textSecondary">Config is empty</Typography>
                  )}
                </Box>
              )}
              {props.service.isWeb() && (
                <Box height={1} width={1}>
                  <Typography>Build: N/A</Typography>
                  <Typography>Commit: N/A</Typography>
                  <Typography>Branch: N/A</Typography>
                </Box>
              )}
            </>
          ) : (
            <Button onClick={() => addService()}>Initialize</Button>
          )}
        </Box>
      </Box> */}
    </SkeletonCustom>
  );
};
