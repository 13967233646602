export const DATE_FORMATS = {
  dmSeconds: 'DD.MM HH:mm:ss',
};

/**
 *
 * @param {Date} date
 */
export const createFirestoreTimestamp = (date) => {
  return {
    seconds: Math.floor(date.getTime() / 1000),
    nanoseconds: date.getTime(),
  };
};
