import firebase from 'firebase/app';
import produce from 'immer';
import keyBy from 'lodash.keyby';
import slugify from 'slugify';
import { initClientEnv } from 'lib/client/clientEnv';
import { EnvNotFoundError } from 'lib/error/error';
import { db } from 'lib/firebase/firebase';

/**
 * @param {ClientDto} clientDto
 * @param {Object<string, import('lib/service/service').ServiceDto>} [servicesDto]
 * @param {Object<string, ProductDto>} [allProducts]
 */
export const initClient = (clientDto, servicesDto, allProducts) => {
  const getId = () => clientDto.id;
  const getName = () => clientDto.name || '[no name]';
  const getDescription = () => clientDto.description || '';
  const getEnvs = () => {
    return Object.entries(clientDto.envs || {}).reduce((result, [envId, envDto]) => {
      return {
        ...result,
        [envId]: initClientEnv({ ...envDto, id: envId }, { client: self, servicesDto, allProducts }),
      };
    }, /** @type {{[id: string]: import('lib/client/clientEnv').ClientEnv}} */ ({}));
  };

  /**
   * @param {string} [id]
   * @todo Make parameter `id` mandatory.
   */
  const getEnv = (id) => {
    let env;
    if (id) {
      env = getEnvs()[id];
    } else {
      env = Object.values(getEnvs())[0];
    }
    if (!env) {
      throw new EnvNotFoundError();
    }
    return env;
  };

  /**
   * @param {(draft: ClientDto) => void} produceFn
   */
  const update = async (produceFn) => {
    const updated = produce(clientDto, produceFn);
    await db.doc(`clients/${clientDto.id}`).set(updated);
    return updated;
  };

  const getDto = () => clientDto;

  const self = {
    getId,
    getName,
    getDescription,
    /** @deprecated */
    id: clientDto.id,
    /** @deprecated */
    name: clientDto.name || 'name',
    getEnv,
    getEnvs,
    update,
    /** @deprecated */
    raw: clientDto,
    getDto,
  };

  return self;
};

/** @param {Partial<ClientDto>} values */
export const createClient = (values) => {
  if (!values.name) throw new Error('Invalid client name');
  const id = slugify(values.name, {
    lower: true,
    remove: /[*+~.()'"!:@]/g,
  });
  const save = async () => {
    const newClient = {
      envs: { production: { name: 'Production' } },
      ...values,
      createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    };
    await db.doc(`clients/${id}`).set(newClient);
    return initClient({ ...values, id });
  };

  return {
    id,
    save,
  };
};

/**
 *
 * @param {import('lib/client/client').ClientDto[]} clientsDto
 * @param {Object<string, import('lib/service/service').ServiceDto>} services
 */
export const initClients = (clientsDto = [], services = {}) => {
  const clients = (clientsDto || []).map((clientDto) => {
    return initClient(clientDto, services);
  });
  return keyBy(clients, 'id');
};

/**
 * @typedef {ReturnType<initClient>} Client
 * @typedef {ReturnType<initClients>} Clients
 *
 * @typedef {Object} ClientDto
 * @prop {string} id
 * @prop {string} [name]
 * @prop {string} [description]
 * @prop {{
 *  [id: string]: import('lib/client/clientEnv').ClientEnvDto
 * }} [envs]
 *
 * @typedef {Object}  ClientProduct
 * @prop {string} id
 */

/** @typedef {import('lib/client/clientService').ClientService} ClientService */
/** @typedef {import('lib/product/product').Product} Product */
/** @typedef {import('lib/service/service').Service} Service */
/** @typedef {{[id: string]: import('lib/product/product').Product}} ClientProductsMap */
/** @typedef {import('lib/product/product').ProductDto} ProductDto */
