import React from 'react';
import { useFirestoreCol } from 'hooks/firebaseHooks';
import { db } from 'lib/firebase/firebase';

/** @type {ProductsContext} */
const defaultContext = {
  products: null,
};

const productPlaceholders = [
  {
    id: 'productId1',
    name: 'Product Name 2',
  },
  {
    id: 'productId1',
    name: 'Product Name 2',
  },
];

export const ProductsContext = React.createContext(defaultContext);

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 */
export function ProductsProvider({ children }) {
  const products = useFirestoreCol(
    /** @type {firebase.default.firestore.CollectionReference<ProductDto[]>} */ (db.collection('products')),
    { placeholderData: productPlaceholders }
  );
  return <ProductsContext.Provider value={{ products }}>{children}</ProductsContext.Provider>;
}

/**
 * @typedef {{
 *  products: import('react-query').UseQueryResult<ProductDto[]>?
 * }} ProductsContext
 */
/** @typedef {import('lib/product/product').ProductDto} ProductDto */
