import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { OutlinedButton } from 'components/Button/OutlinedButton';
import { useClients, useIsLoading } from 'components/Clients/clientHooks';
import { ClientStatus } from 'components/Clients/ClientStatus';
import { NewClientDialog } from 'components/Clients/NewClientDialog';
import { Content } from 'components/Content/Content';
import { SkeletonCustom } from 'components/SkeletonCustom/SkeletonCustom';
import { useUI } from 'components/UIContext/uiHooks';

export const Clients = () => {
  const clients = useClients();
  const theme = useTheme();
  const { openDialog } = useUI();
  const isLoading = useIsLoading();

  return (
    <Content style={{ height: '100%' }}>
      <Box display="flex" marginTop={2} justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h6" component="h2" style={{ fontWeight: theme.typography.fontWeightMedium }}>
            Clientes
          </Typography>
        </Box>
        <Box>
          <OutlinedButton onClick={() => openDialog(() => <NewClientDialog />)}>
            <AddIcon fontSize="small" sx={{ marginRight: theme.spacing(1) }} />
            Crear cliente
          </OutlinedButton>
        </Box>
      </Box>

      <Box marginTop={2} marginBottom={2}>
        <Divider />
      </Box>

      {Object.values(clients.data).map((client) => (
        <SkeletonCustom isActive={isLoading} key={client.getId()}>
          <ClientStatus client={client} />
        </SkeletonCustom>
      ))}
    </Content>
  );
};
