import trimEnd from 'lodash/trimEnd';

export const routes = {
  homepage: '/',
  clients: '/clients/:clientId?',
  clientDetail: '/clients/:clientId/:envId?/:envSection?',
  clientProductDetail: '/clients/:clientId/product/:productId/:envId?',
  clientService: '/clients/:clientId/:envId/services/:serviceId',
  administration: '/administration',
  services: '/services',
};

/**
 * Returns URL with substituted params specified in `params`.
 * @param {string} pattern URL with parameters
 * @param {{[param: string]: string|number|boolean}} [params]
 */
export const getRoute = (pattern, params = {}) => {
  const route = Object.keys(params).reduce((url, key) => {
    const replaceWith = params[key] || '';
    return url.replace(`:${key}`, replaceWith.toString());
  }, pattern);
  return trimEnd(route.replace('?', ''), '?/');
};
