import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useClient } from 'components/Clients/clientHooks';
import { CustomTab, CustomTabs } from 'components/CustomTabs/CustomTabs';
import { getRoute, routes } from 'lib/routes';

/**
 * @param {Object} props
 * @param {JSX.Element|JSX.Element[]|string} [props.children]
 */
export const ClientTabs = (props) => {
  /** @type {{envId?:string, envSection?: string}}*/
  const params = useParams();
  const history = useHistory();
  const client = useClient();
  const envSection = params.envSection || 'services';

  const handleEnvSectionChange = (change) => {
    history.push(
      getRoute(routes.clientDetail, {
        clientId: client.getId(),
        envId: change.envId || params.envId || client.getEnv().getId(),
        envSection: change.envSection || envSection,
      })
    );
  };

  return (
    <CustomTabs
      value={envSection}
      onChange={(e, envSection) => handleEnvSectionChange({ envSection })}
      scrollButtons="auto"
    >
      <CustomTab label="Servicios" value="services" />
      <CustomTab label="Acceso" value="access" />
      <CustomTab label="Tomcat" value="tomcat" />
      <CustomTab label="Bitácora" value="activityLog" />
    </CustomTabs>
  );
};
