import React from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: { default: '#fafafa' },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        '.jsoneditor' {
          borderColor: 'rgba(0, 0, 0, 0.23)', // Same as border of outlined button
          borderRadius: 10,
        }
      `,
    },
  },
});

/**
 * @param {Object} props
 * @param {any} [props.children]
 */
export const ThemeProvider = (props) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </StyledEngineProvider>
  );
};
