import React from 'react';
import { Box, Typography } from '@mui/material';
import { useClientService } from 'components/Clients/clientHooks';

/**
 *
 * @param {Object} props
 */
export const ServiceWeb = (props) => {
  const clientService = useClientService();
  return (
    <>
      <Typography display="inline">Git repo: </Typography>
      <Typography display="inline">
        <span role="img" aria-label="in progress">
          🚧
        </span>
      </Typography>
      <Box height={1} width={1}>
        <Typography>Latest commit: {clientService.getStatus()?.commit || 'N/A'}</Typography>
        <Typography>Branch: {clientService.getStatus()?.branch || 'N/A'}</Typography>
      </Box>
    </>
  );
};
