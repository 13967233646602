import React from 'react';
import { TextField, Box, useTheme, Typography, Link } from '@mui/material';
import { Button, ButtonGroup } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { useClientEnv, useIsLoading } from 'components/Clients/clientHooks';
import { SkeletonCustom } from 'components/SkeletonCustom/SkeletonCustom';

export const ActivityLog = () => {
  const theme = useTheme();
  const clientEnv = useClientEnv();
  const activityLog = clientEnv.getActivityLog();
  const defaultValues = {
    activityLog: activityLog || '',
  };
  const form = useForm({ defaultValues });
  const [isSaving, setIsSaving] = React.useState(false);
  const isLoading = useIsLoading();
  const [mode, setMode] = React.useState('view');

  const { setValue } = form;
  React.useEffect(() => {
    setMode(activityLog ? 'view' : 'edit');
    setValue('activityLog', activityLog || '');
  }, [activityLog, setValue]);

  /** @param {typeof defaultValues} values */
  const save = async (values) => {
    setIsSaving(true);
    await clientEnv.update((envDraft) => {
      envDraft.activityLog = values.activityLog;
    });
    setMode('view');
    setIsSaving(false);
  };

  return (
    <SkeletonCustom isActive={isLoading}>
      <Box display="flex" flexDirection="column">
        <ButtonGroup variant="text" size="small" style={{ alignSelf: 'flex-end' }}>
          <Button
            style={{ color: theme.palette.text.secondary }}
            disabled={mode === 'view'}
            onClick={() => setMode('view')}
          >
            View
          </Button>
          <Button
            style={{ color: theme.palette.text.secondary }}
            onClick={() => setMode('edit')}
            disabled={mode === 'edit'}
          >
            Edit
          </Button>
        </ButtonGroup>

        {mode === 'edit' ? (
          <>
            <Controller
              name="activityLog"
              control={form.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Log de actividades"
                  multiline
                  rows={20}
                  fullWidth
                  placeholder="Por ejemplo: 01/03 Actualizada la versión de Java"
                  variant="outlined"
                />
              )}
            />
            <Box display="flex" justifyContent="space-between" marginTop={1}>
              <Typography color="textSecondary" variant="body2">
                Tip: Puedes usar{' '}
                <Link href="https://commonmark.org/help/" target="_blank">
                  Markdown
                </Link>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(save)}
                style={{ alignSelf: 'flex-end' }}
                disabled={isSaving}
              >
                Guardar
              </Button>
            </Box>
          </>
        ) : (
          <ReactMarkdown>{activityLog || ''}</ReactMarkdown>
        )}
      </Box>
    </SkeletonCustom>
  );
};
