import React from 'react';
import { Button, useTheme } from '@mui/material';

/**
 * @param {import('@mui/material').ButtonProps} props
 */
export const OutlinedButton = (props = {}) => {
  const theme = useTheme();
  const { children, style, ...restProps } = props;
  return (
    <Button
      variant="outlined"
      style={{
        ...(style || {}),
        borderColor: theme.palette.divider,
        textTransform: 'none',
        alignSelf: 'center',
        fontWeight: theme.typography.fontWeightRegular,
      }}
      {...restProps}
    >
      {props.children}
    </Button>
  );
};
