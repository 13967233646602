export const config = {
  production: {
    apiKey: 'AIzaSyCcNaranvWJJYjoL7ZDyTVlWDVGC0TkIPc',
    authDomain: 'emi-admin.firebaseapp.com',
    projectId: 'emi-admin',
    storageBucket: 'emi-admin.appspot.com',
    messagingSenderId: '628348144494',
    appId: '1:628348144494:web:82eef2300c91bfb1828f7f',
  },
};
