import React from 'react';
import { AppBar, Box, Button, Toolbar, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useUI } from 'components/UIContext/uiHooks';
import { auth } from 'lib/firebase/firebase';
import { getRoute, routes } from 'lib/routes';

export const Header = () => {
  const history = useHistory();
  const theme = useTheme();
  const { setContentHeight } = useUI();
  /** @type {React.MutableRefObject<HTMLElement|undefined>} */
  const wrapperRef = React.useRef();
  const height = wrapperRef.current?.getBoundingClientRect().height;

  React.useEffect(() => {
    if (!height) return;
    setContentHeight(`calc(100vh - ${height}px)`);
  }, [height, setContentHeight]);

  return (
    <AppBar position="sticky" color="inherit">
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          onClick={() => history.push(getRoute(routes.clients, { clientId: '' }))}
          style={{ marginRight: theme.spacing(3), cursor: 'pointer' }}
        >
          Clientes
        </Typography>
        <Typography
          variant="h6"
          component="div"
          onClick={() => history.push(getRoute(routes.services))}
          style={{ marginRight: theme.spacing(3), cursor: 'pointer' }}
        >
          Servicios
        </Typography>
        <Box display="flex" flexGrow={1} />
        <Button
          color="inherit"
          onClick={() => {
            auth.signOut();
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};
