/**
 * @param {ClientServiceDto} clientServiceDto
 * @param {{
 *  serviceDto: ServiceDto | undefined
 *  clientEnv: ClientEnv
 * }} refs
 */
export const initClientService = (clientServiceDto, refs) => {
  const getId = () => clientServiceDto.id;
  const getName = () => refs.serviceDto?.name || '[Missing service name]';
  const isJson = () => refs.serviceDto?.type === 'json';
  const isCustomData = () => refs.serviceDto?.type === 'customData';
  const isWeb = () => refs.serviceDto?.type === 'web';
  const isApi = () => refs.serviceDto?.type === 'api';
  const getStatus = () => clientServiceDto.status;
  const getClientEnv = () => refs.clientEnv;

  return { getId, getName, isJson, isCustomData, isWeb, isApi, getStatus, getClientEnv };
};

/**
 * @typedef {ReturnType<initClientService>} ClientService
 */

/** @typedef {{
 *    status: 'online'|'offline'|'unknown',
 *    commit: string,
 *    commitMessage: string,
 *    branch: string,
 *    version: string,
 *    updatedDate: string
 *  }} ClientServiceStatus
 */

/**
 * @typedef {Object} ClientServiceDto
 * @prop {string} id
 * @prop {Object} [versionHistory]
 * @prop {ClientServiceStatus} [status]
 */

/** @typedef {import('lib/product/product').Product} Product */
/** @typedef {import('lib/service/service').ServiceDto} ServiceDto */
/** @typedef {{[id: string]: import('lib/product/product').Product}} ClientProductsMap */
/** @typedef {import('lib/client/client').Client} Client */
/** @typedef {import('lib/client/clientEnv').ClientEnv} ClientEnv */
