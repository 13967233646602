import React from 'react';
import { useFirestoreCol } from 'hooks/firebaseHooks';
import { db } from 'lib/firebase/firebase';

/** @type {ClientsContext} */
const defaultContext = {
  clients: null,
};
export const ClientsContext = React.createContext(defaultContext);

const clientsPlaceholders = [
  {
    id: 'clientId1',
    envs: { evn1: { name: 'Env1', products: { product1: {} }, services: { xy: {} } } },
  },
  {
    id: 'clientId2',
    envs: { evn1: { name: 'Env1', products: { product1: {}, product2: {} }, services: { xy: {} } } },
  },
];

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 */
export function ClientsProvider({ children }) {
  const clients = useFirestoreCol(
    /** @type {firebase.default.firestore.CollectionReference<ClientDto[]>} */ (db.collection('clients')),
    { placeholderData: clientsPlaceholders }
  );
  return <ClientsContext.Provider value={{ clients }}>{children}</ClientsContext.Provider>;
}

/**
 * @typedef {{
 *  clients: import('react-query').UseQueryResult<ClientDto[]>?
 * }} ClientsContext
 */
/** @typedef {import('lib/client/client').ClientDto} ClientDto */
