import React from 'react';
import { useParams } from 'react-router';
import { useImmer } from 'use-immer';
import { ClientsContext } from 'components/Clients/ClientsProvider';
import { useProducts } from 'components/Products/productHooks';
import { useService, useServices } from 'components/Services/servicesHooks';
import { initClients } from 'lib/client/client';
import { CLientNotFoundError } from 'lib/error/error';

export const useClients = () => {
  const clientsContext = React.useContext(ClientsContext);
  if (!clientsContext.clients) {
    throw new Error(`Missing ClientsProvider`);
  }
  const clientsDtoQuery = clientsContext.clients;
  const services = useServices();
  const clientsMap = initClients(clientsDtoQuery.data, services.data);
  const [clientsQuery, updateClientsQuery] = useImmer({
    ...clientsDtoQuery,
    data: clientsMap,
  });
  React.useEffect(() => {
    updateClientsQuery(() => {
      return {
        ...clientsDtoQuery,
        data: initClients(clientsDtoQuery.data, services.data),
      };
    });
  }, [clientsDtoQuery, services.data, updateClientsQuery]);

  return clientsQuery;
};

/**
 * @param {string} [clientId]
 */
export const useClient = (clientId) => {
  /** @type {{clientId?: string}} */
  const urlParams = useParams();
  const clients = useClients();
  if (clients.isFetching) {
    return Object.values(clients.data)[0];
  }
  const id = clientId || urlParams.clientId;
  if (!id || !clients.data[id]) {
    throw new CLientNotFoundError();
  }
  return clients.data[id];
};

export const useClientEnv = () => {
  /** @type {{envId?: string}} */
  const urlParams = useParams();
  const client = useClient();
  const isLoading = useIsLoading();
  const clientEnv = client.getEnv(isLoading ? '' : urlParams.envId);
  return clientEnv;
};

export const useClientService = () => {
  /** @type {{envId?: string}} */
  const urlParams = useParams();
  const client = useClient();
  const service = useService();
  return client.getEnv(urlParams.envId).getService(service.getId());
};

/**
 * @todo Move to separate file
 */
export const useIsLoading = () => {
  const clients = useClients();
  const products = useProducts();
  const services = useServices();
  return clients.isFetching || products.isFetching || services.isFetching;
};
