import React from 'react';
import { Box, Button, CircularProgress, Container, TextField, Typography, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { sendLoginEmail } from 'lib/firebase/firebaseFunctions';

/**
 * @param {Object} props
 * @param {JSX.Element|JSX.Element[]|string} [props.children]
 */
export const Login = (props) => {
  const theme = useTheme();
  const defaultValues = {
    ldapUsername: localStorage.getItem('ldapUsername') || '',
  };
  const form = useForm({ defaultValues });
  const sendLoginEmailQuery = useMutation(
    /** @param {string} ldapUsername */ async (ldapUsername) => sendLoginEmail(ldapUsername)
  );

  /** @param {typeof defaultValues} values */
  const onSubmit = async (values) => {
    localStorage.setItem('ldapUsername', values.ldapUsername);
    sendLoginEmailQuery.mutate(values.ldapUsername);
  };
  return (
    <Container
      maxWidth="xs"
      style={{ height: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}
    >
      <Typography variant="h5" align="center" style={{ marginBottom: theme.spacing(2) }}>
        Login
      </Typography>
      <form>
        <Controller
          name="ldapUsername"
          control={form.control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="LDAP nombre"
              variant="outlined"
              fullWidth
              style={{ marginBottom: theme.spacing(2) }}
            />
          )}
        />
        {sendLoginEmailQuery.isSuccess ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CheckIcon style={{ color: theme.palette.success.main, marginRight: theme.spacing(1) }} />
            <Typography align="center">
              Login link mandado a <i>{`${form.getValues('ldapUsername')}@soincon.es`}</i>
            </Typography>
          </Box>
        ) : sendLoginEmailQuery.isError ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <ClearIcon style={{ color: theme.palette.error.main, marginRight: theme.spacing(1) }} />
            <Typography align="center">Habido un fallo durante mandar login link</Typography>
          </Box>
        ) : (
          <Button
            onClick={form.handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={sendLoginEmailQuery.isLoading}
          >
            {sendLoginEmailQuery.isLoading && <CircularProgress size={15} style={{ marginRight: theme.spacing(1) }} />}
            OK
          </Button>
        )}
      </form>
    </Container>
  );
};
