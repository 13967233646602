import React from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Access } from 'components/Access/Access';
import { ActivityLog } from 'components/ActivityLog/ActivityLog';
import { ClientBreadcrumbs } from 'components/Breadcrumbs/ClientBreadcrumbs';
import { useClient, useClientEnv } from 'components/Clients/clientHooks';
import { ClientTabs } from 'components/Clients/ClientTabs';
import { NewClientDialog } from 'components/Clients/NewClientDialog';
import { Content } from 'components/Content/Content';
import { EnvSelect } from 'components/EnvSelect/EnvSelect';
import { ServicesList } from 'components/Services/ServicesList';
import { Tomcat } from 'components/Tomcat/Tomcat';
import { useUI } from 'components/UIContext/uiHooks';

export const ClientRoute = (props) => {
  /** @type {{envId?:string, envSection?: string}}*/
  const params = useParams();
  const theme = useTheme();
  const client = useClient();
  const clientEnv = useClientEnv();
  const envSection = params.envSection || 'services';
  const [isDetailsShown, setIsDetailsShown] = React.useState(false);
  const { openDialog } = useUI();

  return (
    <Content>
      <Box marginTop={1}>
        <ClientBreadcrumbs />
      </Box>
      <Box marginTop={2}>
        <Typography variant="h5" component="h2" style={{ fontWeight: theme.typography.fontWeightMedium }}>
          {client.getName()}
        </Typography>
        <Box marginTop={1}>
          <Link
            variant="body2"
            component="span"
            color="textSecondary"
            style={{ cursor: 'pointer', marginRight: theme.spacing(2) }}
            onClick={() => setIsDetailsShown(!isDetailsShown)}
          >
            {isDetailsShown ? '▼ Ocultar' : '▶ Mostrar'} detalles
          </Link>
          <Link
            variant="body2"
            component="span"
            color="textSecondary"
            style={{ cursor: 'pointer' }}
            onClick={() => openDialog(() => <NewClientDialog client={client} />)}
          >
            Editar
          </Link>
          {isDetailsShown && <Typography style={{ whiteSpace: 'pre-wrap' }}>{client.getDescription()}</Typography>}
        </Box>
      </Box>

      <EnvSelect envId={clientEnv.getId()} />

      {Object.values(client.getEnvs()).length === 0 && (
        <Typography color="textSecondary" align="center" variant="body2">
          No hay entornos
        </Typography>
      )}

      <ClientTabs />

      <Box marginTop={1} />

      {envSection === 'services' && <ServicesList envId={clientEnv.getId()} />}
      {envSection === 'access' && <Access />}
      {envSection === 'tomcat' && <Tomcat />}
      {envSection === 'activityLog' && <ActivityLog />}
    </Content>
  );
};
