import React from 'react';
import { AppBar } from '@mui/material';
import { Content } from 'components/Content/Content';

/**
 * @param {Object} props
 * @param {JSX.Element|JSX.Element[]|string} [props.children]
 */
export const ContentNotLogged = (props) => {
  return (
    <Content style={{ height: '100vh' }}>
      <AppBar position="static" color="transparent" />
      {props.children || null}
    </Content>
  );
};
