import produce from 'immer';
import keyBy from 'lodash/keyBy';
import slugify from 'slugify';
import { db } from 'lib/firebase/firebase';

/**
 * @param {ServiceDto} serviceDto
 */
export const initService = (serviceDto) => {
  const getId = () => serviceDto.id;
  const getName = () => serviceDto.name || 'no-service-name';
  const getType = () => serviceDto.type;
  const isCustomData = () => serviceDto.type === 'customData';
  const isWeb = () => serviceDto.type === 'web';
  const isApi = () => serviceDto.type === 'api';
  const getBuildInfoConfigPath = () => serviceDto.buildInfoConfigPath;
  const getBuildInfoJsonSchemaUri = () => serviceDto.buildInfoJsonSchemaUri;
  const getBuildInfoPath = () => serviceDto.buildInfoPath;
  /**
   * @param {(draft: ServiceDto) => void} produceFn
   */
  const update = (produceFn) => {
    const newServiceDto = produce(serviceDto, produceFn);
    return db.doc(`services/${serviceDto.id}`).set(newServiceDto);
  };

  const save = () => {
    return update(() => serviceDto);
  };

  const remove = () => {
    return db.doc(`services/${serviceDto.id}`).delete();
  };

  return {
    getId,
    /** @deprecated */
    id: serviceDto.id,
    /** @deprecated */
    type: serviceDto.type,
    /** @deprecated */
    name: serviceDto.name || 'no-service-name',
    getName,
    getType,
    getShortName: () => serviceDto.nameShort,
    getBuildInfoConfigPath,
    getBuildInfoJsonSchemaUri,
    getBuildInfoPath,
    getDto: () => serviceDto,
    /** @deprecated */
    dto: serviceDto,
    isCustomData,
    isWeb,
    isApi,
    update,
    save,
    remove,
  };
};

/**
 *
 * @param {import('lib/service/service').ServiceDto[]} [servicesDto]
 */
export const initServices = (servicesDto) => {
  const services = (servicesDto || []).map((serviceDto) => {
    return initService(serviceDto);
  });
  return keyBy(services, 'id');
};

/** @param {{name: string}} values */
export const createServiceDto = (values) => {
  return {
    ...values,
    id: slugify(values.name, {
      lower: true,
      remove: /[*+~.()'"!:@]/g,
    }),
  };
};

/** @typedef {ReturnType<initService>} Service */
/** @typedef {ReturnType<initServices>} Services */
/**
 * @typedef {Object} ServiceDto
 * @prop {string} id
 * @prop {string} [type]
 * @prop {string} [name]
 * @prop {string} [nameShort]
 * @prop {string} [buildInfoConfigPath]
 * @prop {string} [buildInfoJsonSchemaUri]
 * @prop {string} [buildInfoPath]
 */
