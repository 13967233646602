import React from 'react';
import { useParams } from 'react-router';
import { useImmer } from 'use-immer';
import { ServicesContext } from 'components/Services/ServicesProvider';
import { ServiceNotFoundError } from 'lib/error/error';
import { initServices } from 'lib/service/service';

export const useServices = () => {
  const servicesContext = React.useContext(ServicesContext);
  if (!servicesContext.services) {
    throw new Error(`Missing ClientsProvider`);
  }
  const servicesDtoQuery = servicesContext.services;
  const servicesMap = initServices(servicesDtoQuery.data);
  const [servicesQuery, updateServicesQuery] = useImmer({
    ...servicesDtoQuery,
    data: servicesMap,
  });
  React.useEffect(() => {
    updateServicesQuery(() => {
      return {
        ...servicesDtoQuery,
        data: initServices(servicesDtoQuery.data),
      };
    });
  }, [servicesDtoQuery, updateServicesQuery]);

  return servicesQuery;
};

/**
 * @param {string} [serviceId]
 */
export const useService = (serviceId) => {
  /** @type {{serviceId?: string}} */
  const urlParams = useParams();
  const services = useServices();
  if (services.isFetching) {
    return Object.values(services.data)[0];
  }
  const id = serviceId || urlParams.serviceId;
  if (!id || !services.data[id]) {
    throw new ServiceNotFoundError();
  }
  return services.data[id];
};
