import React from 'react';
import { Box, Typography, useTheme, Link } from '@mui/material';
import { NewAccessDialog } from 'components/Access/NewAccessDialog';
import { OutlinedButton } from 'components/Button/OutlinedButton';
import { useClientEnv } from 'components/Clients/clientHooks';
import { useUI } from 'components/UIContext/uiHooks';

export const Access = () => {
  const theme = useTheme();
  const clientEnv = useClientEnv();
  const access = clientEnv.getAccess();
  const { openDialog } = useUI();

  return (
    <Box>
      {access ? (
        <>
          <Typography style={{ whiteSpace: 'pre-wrap' }}>{access}</Typography>
          <Box marginTop={2}>
            <OutlinedButton onClick={() => openDialog(() => <NewAccessDialog clientEnv={clientEnv} />)}>
              Editar
            </OutlinedButton>
          </Box>
        </>
      ) : (
        <Box
          width={1}
          marginBottom={2}
          padding={2}
          bgcolor={theme.palette.grey[200]}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>
            No hay instrucciones cómo acceder.{' '}
            <Link
              style={{ cursor: 'pointer' }}
              onClick={() => {
                openDialog(() => <NewAccessDialog clientEnv={clientEnv} />);
              }}
            >
              Añadir
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
