import React from 'react';
import { useHistory } from 'react-router-dom';
import { ContentNotLogged } from 'components/Content/ContentNotLogged';
import { Login } from 'components/Login/Login';
import { auth } from 'lib/firebase/firebase';
import { routes } from 'lib/routes';

const FirebaseAuthContext = React.createContext(/** @type {{user: import('firebase').default.User }} */ ({}));

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 */
export function FirebaseAuthProvider(props) {
  const [user, setUser] = React.useState(/** @type {undefined | null | import('firebase').default.User} */ (undefined));
  const history = useHistory();

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (auth.isSignInWithEmailLink(window.location.href)) {
        try {
          let ldapUsername = localStorage.getItem('ldapUsername');
          if (!ldapUsername) {
            ldapUsername = window.prompt('Cual es tu LDAP usuario?');
          }
          if (!ldapUsername) {
            throw new Error('No LDAP username');
          }
          await auth.signInWithEmailLink(`${ldapUsername}@soincon.es`);
          const url = new URL(window.location.href);
          const refPath = url.searchParams.get('refPath');
          history.replace(refPath || routes.homepage);
        } catch (e) {
          console.log('🛎 ', 'err', e);
        }
      }
    })();
  }, [history]);

  if (!user) {
    return <ContentNotLogged>{user === null && <Login />}</ContentNotLogged>;
  }

  return <FirebaseAuthContext.Provider value={{ user }}>{props.children}</FirebaseAuthContext.Provider>;
}

export const useAuth = () => {
  return React.useContext(FirebaseAuthContext);
};
