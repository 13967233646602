import React from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Dialog } from 'components/Dialog/Dialog';
import { useServices } from 'components/Services/servicesHooks';
import { useUI } from 'components/UIContext/uiHooks';
import { createServiceDto, initService } from 'lib/service/service';

/**
 * @param {Object} props
 * @param {import('lib/service/service').Service} [props.service]
 */
export const NewServiceDialog = (props) => {
  const { closeDialog } = useUI();
  const services = useServices();
  const defaultValues = {
    name: props.service?.getName() || '',
    type: props.service?.getType() || 'web',
    buildInfoConfigPath: props.service?.getBuildInfoConfigPath() || '',
    buildInfoJsonSchemaUri: props.service?.getBuildInfoJsonSchemaUri() || '',
    buildInfoPath: props.service?.getBuildInfoPath() || '',
  };
  const form = useForm({
    defaultValues,
  });
  const type = form.watch('type');

  /** @param {typeof defaultValues} values */
  const save = async (values) => {
    if (props.service) {
      await props.service.update((draft) => {
        Object.assign(draft, values);
      });
    } else {
      const serviceDto = createServiceDto(values);
      const service = initService(serviceDto);
      await service.save();
    }
    closeDialog();
  };

  return (
    <Dialog
      title={`Nuevo servicio`}
      primaryButton={() => (
        <Button onClick={form.handleSubmit(save)} variant={'contained'} color={'primary'}>
          Create
        </Button>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={form.control}
            rules={{
              required: true,
              validate: (name) => !serviceExists(services.data, name) || 'Service already exists',
            }}
            render={({ field, formState }) => (
              <TextField {...field} label="Nombre" error={!!formState.errors.name} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="type"
            control={form.control}
            rules={{
              required: true,
              validate: (name) => !serviceExists(services.data, name) || 'Service already exists',
            }}
            render={({ field, formState }) => (
              <TextField select {...field} label="Tipo" error={!!formState.errors.type} fullWidth>
                <MenuItem value="web">Web</MenuItem>
                <MenuItem value="api">API</MenuItem>
                {/* <MenuItem value="customData">Custom data</MenuItem> */}
                <MenuItem value="other">Otros</MenuItem>
              </TextField>
            )}
          />
        </Grid>
        {type === 'web' ? (
          <Grid item xs={12} sm={6}>
            <Controller
              name="buildInfoPath"
              control={form.control}
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  label="Ruta para build info"
                  error={!!formState.errors.type}
                  fullWidth
                  placeholder="my-factory/build.json"
                  helperText="Ruta para JSON con informacion sobre el build"
                />
              )}
            />
          </Grid>
        ) : type === 'api' ? (
          <>
            <Grid item xs={12} sm={6}>
              <Controller
                name="buildInfoConfigPath"
                control={form.control}
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    label="Posicion en config.json"
                    error={!!formState.errors.type}
                    fullWidth
                    placeholder="mf.api"
                    helperText="Como lo entiende lodash.get"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="buildInfoJsonSchemaUri"
                control={form.control}
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    label="Swagger doc path"
                    error={!!formState.errors.type}
                    fullWidth
                    placeholder="v3/api-docs"
                    helperText="Ruta para el esquema Swagger"
                  />
                )}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </Dialog>
  );
};

/**
 * @param {import('lib/service/service').Services} services
 * @param {string} name
 */
const serviceExists = (services, name) => {
  const { id } = createServiceDto({ name });
  return !!services[id] && services[id].getId() !== id;
};
