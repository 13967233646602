import JSZip from 'jszip';
import { webXml } from 'consts/configConsts';
import { createFirestoreTimestamp } from 'lib/date';
import { db } from 'lib/firebase/firebase';
import { sha256 } from 'utils/stringUtils';

/**
 *
 * @param {EnvConfigDto} configDto
 */
export const initConfig = (configDto) => {
  const getId = () => configDto.id;
  const getContent = () => {
    return Object.keys(configDto.content)
      .sort()
      .reduce((obj, key) => {
        obj[key] = configDto.content[key];
        return obj;
      }, /** @type {import('@snc/emi-config').Config} */ ({}));
  };
  const getCreatedDate = () => configDto.createdDate;
  const getCreatedDateParsed = () => configDto.createdDate?.seconds && new Date(configDto.createdDate.seconds * 1000);

  const generate = async () => {
    const hash = await sha256(JSON.stringify(configDto.content));
    return {
      ...configDto.content,
      _meta: {
        signature: hash,
        version: 1,
      },
    };
  };

  /**
   *
   * @param {Object} content
   * @todo Maybe can be part of Tomcat, because I need to pass `content` which is strange.
   * @return {Promise<War>}
   */
  const createWar = async (content) => {
    var zip = new JSZip();
    if (!content) throw new Error('Config is empty');
    zip.file('config.json', JSON.stringify(content, null, 2));
    var webInfFolder = zip.folder('WEB-INF');
    if (webInfFolder === null) throw new Error('Cannot create folder in ZIP file');
    webInfFolder.file('web.xml', webXml);
    const war = await zip.generateAsync({ type: 'blob' });
    return war;
  };

  /**
   * @param {import('lib/client/clientEnv').ClientEnv} clientEnv
   * @param {any} user
   */
  const saveConfig = async (clientEnv, user) => {
    const client = clientEnv.getClient();
    const createdDate = new Date();
    const newConfigDto = {
      ...configDto,
      envId: clientEnv.getId(),
      createdDate,
      createdBy: { userId: user.uid },
    };
    const { id: envConfigId } = await db.collection(`clients/${client.getId()}/envConfigs`).add(newConfigDto);
    return {
      ...newConfigDto,
      createdDate: createFirestoreTimestamp(createdDate),
      id: envConfigId,
    };
  };

  return {
    generate,
    createWar,
    getContent,
    saveConfig,
    getId,
    getCreatedDate,
    getCreatedDateParsed,
  };
};

/**
 *
 * @param {import('@snc/emi-config').Config} content
 * @return {EnvConfig}
 */
export const createEnvConfig = (content) => {
  return initConfig({ content });
};

/** @typedef {Blob} War */
/** @typedef {ReturnType<initConfig>} EnvConfig */

/** @typedef {{
 *  id: string
 *  content: import('@snc/emi-config').Config
 *  createdDate: {seconds: number, nanoseconds: number}
 * }} EnvConfigDto */
