import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import sortBy from 'lodash/sortBy';
import { AddClientServiceDialog } from 'components/Clients/AddClientServiceDialog';
import { useClient } from 'components/Clients/clientHooks';
import { ServicesListItem } from 'components/Services/ServicesListItem';
import { useUI } from 'components/UIContext/uiHooks';

/**
 *
 * @param {Object} props
 * @param {string} props.envId
 */
export const ServicesList = (props) => {
  const client = useClient();
  const theme = useTheme();
  const { openDialog } = useUI();
  const clientServices = client.getEnv(props.envId).getServices();
  const clientServicesSorted = sortBy(Object.values(clientServices), (clientService) => clientService.getName());
  return (
    <>
      <Box
        width={1}
        marginBottom={1}
        display="flex"
        justifyContent="center"
        border={1}
        borderRadius="undefinedpx"
        style={{ color: theme.palette.text.secondary, borderStyle: 'dashed', borderColor: theme.palette.divider }}
        onClick={() => {
          openDialog(() => <AddClientServiceDialog client={client} envId={props.envId} />);
        }}
      >
        <Button style={{ width: '100%', color: theme.palette.text.secondary }}>
          <AddIcon fontSize="small" />
          Add Service
        </Button>
      </Box>
      {clientServicesSorted.map((service) => (
        <ServicesListItem service={service} envId={props.envId} key={service.getId()} />
      ))}
    </>
  );
};
