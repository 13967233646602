import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { ButtonGroup } from '@mui/material';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { useClient, useClientEnv, useIsLoading } from 'components/Clients/clientHooks';
import { useEnvConfig } from 'hooks/envConfigHooks';
import { getQueryDocKey, useFirestoreCol } from 'hooks/firebaseHooks';
import { DATE_FORMATS } from 'lib/date';
import { db } from 'lib/firebase/firebase';

/**
 *
 * @param {Object} props
 * @param {Function} props.setVersion
 * @param {string} props.version
 */
export const ConfigVersion = (props) => {
  const theme = useTheme();
  const client = useClient();
  const queryClient = useQueryClient();
  const clientEnv = useClientEnv();
  const isLoading = useIsLoading();
  const envConfigQuery = useEnvConfig(props.version, { enabled: !!props.version && !isLoading });
  const createdDate = envConfigQuery.data?.getCreatedDate() || null;
  const createdDateParsed = envConfigQuery.data?.getCreatedDateParsed();

  /** @param {boolean} isPrev */
  const getQuery = (isPrev) =>
    db
      .collection(`clients/${client.getId()}/envConfigs`)
      .where('envId', '==', clientEnv.getId())
      .orderBy('createdDate', isPrev ? 'desc' : 'asc')
      .startAfter(createdDate)
      .limit(1);
  const options = {
    enabled: false,
    onSuccess: (results) => {
      if (!Array.isArray(results) || results.length !== 1) {
        // It's last - do nothing
        return;
      }
      const result = results[0];
      queryClient.setQueryData(getQueryDocKey(useEnvConfig.getKey(client.getId(), result.id)), result);
      props.setVersion(result.id);
    },
  };

  const nextQuery = useFirestoreCol(getQuery(false), options);
  const prevQuery = useFirestoreCol(getQuery(true), options);

  const isLatest = clientEnv.getEnvConfigId() === props.version;

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      {createdDateParsed && (
        <Typography variant="body2" color="textSecondary" style={{ marginRight: theme.spacing(2) }}>
          Guardado: {dayjs(createdDateParsed).format(DATE_FORMATS.dmSeconds)}
        </Typography>
      )}
      <ButtonGroup variant="text" size="small">
        <Button style={{ color: theme.palette.text.secondary }} onClick={() => prevQuery.refetch()}>
          Previous
        </Button>
        <Button style={{ color: theme.palette.text.secondary }} disabled={isLatest} onClick={() => nextQuery.refetch()}>
          Next
        </Button>
      </ButtonGroup>
    </Box>
  );
};
