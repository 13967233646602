import produce from 'immer';
import keyBy from 'lodash/keyBy';
import slugify from 'slugify';
import { db } from 'lib/firebase/firebase';

/**
 * @param {ProductDto} productDto
 */
export const initProduct = (productDto) => {
  const getId = () => productDto.id || 'no-id';
  const getName = () => productDto.name || 'no-name';
  const getDependencies = () => productDto.dependencies || {};
  const getDto = () => productDto;

  /**
   * @param {(draft: ProductDto) => void} produceFn
   */
  const update = (produceFn) => {
    const newProductDto = produce(productDto, produceFn);
    return db.doc(`products/${productDto.id}`).set(newProductDto);
  };

  const save = () => {
    return update(() => productDto);
  };

  const remove = () => {
    return db.doc(`products/${productDto.id}`).delete();
  };

  return {
    getId,
    getName,
    getDependencies,
    getDto,
    update,
    save,
    remove,
    /** @deprecated */
    id: productDto.id || 'no-id',
    /** @deprecated */
    name: productDto.name || 'no-name',
    /** @deprecated */
    dependencies: productDto.dependencies || {},
  };
};

/** @param {{name: string}} values */
export const createProductDto = (values) => {
  return {
    ...values,
    id: slugify(values.name, {
      lower: true,
      remove: /[*+~.()'"!:@]/g,
    }),
  };
};

/**
 * @param {import('lib/product/product').ProductDto[]} [productsDto]
 */
export const initProducts = (productsDto) => {
  const products = (productsDto || []).map((productDto) => {
    return initProduct(productDto);
  });
  return keyBy(products, 'id');
};

/**
 * @typedef {ReturnType<initProduct>} Product
 */

/**
 * @typedef {Object} ProductDto
 * @prop {string} id
 * @prop {string} [name]
 * @prop {Object<string, Object>} [dependencies]
 */

/** @typedef {ReturnType<initProducts>} Products */
