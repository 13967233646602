import React from 'react';
import { Box, Link, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import { useHistory, useParams } from 'react-router';
import { useClient } from 'components/Clients/clientHooks';
import { NewEnvironmentDialog } from 'components/Clients/NewEnvironmentDialog';
import { useUI } from 'components/UIContext/uiHooks';
import { getRoute, routes } from 'lib/routes';

/**
 *
 * @param {Object} props
 * @param {string} props.envId
 */
export const EnvSelect = (props) => {
  /** @type {{productId?: string, serviceId?: string}}*/
  const urlParams = useParams();
  const client = useClient();
  const theme = useTheme();
  const history = useHistory();
  const { openDialog } = useUI();
  /** @type {React.MutableRefObject<HTMLElement|undefined>} */
  const el = React.useRef();

  const elPosition = el.current?.getBoundingClientRect();

  /** @param {string} envId */
  const handleEnvChange = (envId) => {
    let route = routes.clientDetail;
    if (urlParams.serviceId) {
      route = routes.clientService;
    }
    history.push(getRoute(route, { ...urlParams, envId }));
  };

  const options = Object.values(client.getEnvs()).filter((env) => {
    if (!urlParams.serviceId) return true;
    try {
      env.getService(urlParams.serviceId);
      return true;
    } catch (e) {
      return false;
    }
  });

  return (
    <>
      {el.current && (
        <Box
          position="absolute"
          marginTop={1}
          style={{
            backgroundColor: theme.palette.grey[200],
            width: 'calc(100vw)',
            left: 0,
            height: elPosition?.height,
          }}
        />
      )}
      <Box display="flex" style={{ position: 'relative' }} marginTop={1} paddingY={1} ref={el}>
        <Box mr={8}>
          <TextField
            value={props.envId}
            variant="outlined"
            select
            fullWidth
            margin="dense"
            style={{ backgroundColor: theme.palette.common.white }}
            onChange={(event) => handleEnvChange(event.target.value)}
          >
            {options.map((env) => (
              <MenuItem value={env.getId()} key={env.getId()}>
                {env.getName()}
              </MenuItem>
            ))}
          </TextField>
          <Typography variant="body2" color="textSecondary">
            Elige entorno que quieres editar
            {urlParams.serviceId ? (
              '.'
            ) : (
              <>
                {' o '}
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    openDialog(() => (
                      <NewEnvironmentDialog client={client} onCreate={(env) => handleEnvChange(env.getId())} />
                    ));
                  }}
                >
                  crea uno nuevo
                </Link>
              </>
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
