import React from 'react';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useClient } from 'components/Clients/clientHooks';
import { getRoute, routes } from 'lib/routes';

/**
 *
 * @param {Object} props
 * @param {JSX.Element|JSX.Element[]|string} [props.children]
 */
export const ClientBreadcrumbs = (props) => {
  const client = useClient();
  return (
    <Breadcrumbs>
      <Breadcrumbs.Item to={getRoute(routes.clients, { clientId: '' })}>Clients</Breadcrumbs.Item>
      <Breadcrumbs.Item isLast>{client.getName()}</Breadcrumbs.Item>
    </Breadcrumbs>
  );
};
