import React from 'react';
import { Box, Typography } from '@mui/material';

export const Field = (props) => {
  return (
    <Box width={1} marginBottom={props.marginBottom || 0}>
      <Typography variant="body2" color="textSecondary">
        {props.title}
      </Typography>
      {typeof props.value === 'function' ? props.value() : <Typography>{props.children}</Typography>}
    </Box>
  );
};
