import React from 'react';
import { Skeleton } from '@mui/material';

/**
 *
 * @param {{
 *  isActive: boolean
 *  children: any
 * }} props
 */
export const SkeletonCustom = (props) => {
  if (props.isActive) {
    return <Skeleton>{props.children}</Skeleton>;
  }
  return props.children;
};
