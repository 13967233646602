import React from 'react';
import { Container, useTheme } from '@mui/material';
import { useUI } from 'components/UIContext/uiHooks';

/**
 * @param {Object} props
 * @param {boolean} [props.fitWindow]
 * @param {Object} [props.style]
 * @param {any} props.children
 */
export const Content = (props) => {
  const { contentHeight } = useUI();
  const theme = useTheme();

  return (
    <Container
      style={{ paddingBottom: theme.spacing(7) }}
      maxWidth="md"
      {...((props.fitWindow || props.style) && { style: { height: contentHeight, ...(props.style || {}) } })}
    >
      {props.children}
    </Container>
  );
};
