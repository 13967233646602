import React from 'react';
import { Box, Typography, InputBase, useTheme, Link } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { OutlinedButton } from 'components/Button/OutlinedButton';
import { useClientEnv } from 'components/Clients/clientHooks';
import { Field } from 'components/Field/Field';
import { NewTomcatDialog } from 'components/Tomcat/NewTomcatDialog';
import { useUI } from 'components/UIContext/uiHooks';

/**
 */
export const Tomcat = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const theme = useTheme();
  const clientEnv = useClientEnv();
  const tomcat = clientEnv.getTomcat();
  const { openDialog } = useUI();

  const handleToggleHide = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Box>
      {tomcat ? (
        <>
          <Field title="URL" marginBottom={2}>
            {tomcat.url}
          </Field>
          <Field title="User" marginBottom={2}>
            {tomcat.managerUser}
          </Field>
          <Field
            title="Password"
            marginBottom={2}
            value={() => (
              <InputBase
                value={tomcat.managerPassword}
                disabled
                type={showPassword ? 'text' : 'password'}
                autoComplete="off"
                style={{ color: theme.palette.text.primary }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleHide} edge="end" size="large">
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
          <OutlinedButton onClick={() => openDialog(() => <NewTomcatDialog clientEnv={clientEnv} />)}>
            Editar
          </OutlinedButton>
        </>
      ) : (
        <Box
          width={1}
          marginBottom={2}
          padding={2}
          bgcolor={theme.palette.grey[200]}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>
            Tomcat no está configurado.{' '}
            <Link
              style={{ cursor: 'pointer' }}
              onClick={() => {
                openDialog(() => <NewTomcatDialog clientEnv={clientEnv} />);
              }}
            >
              Configurar
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
