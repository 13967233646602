import { useClient } from 'components/Clients/clientHooks';
import { useFirestoreDoc } from 'hooks/firebaseHooks';
import { initConfig } from 'lib/envConfig/envConfig';
import { db } from 'lib/firebase/firebase';

/**
 *
 * @param {string} [envConfigId]
 * @param {import('react-query').UseQueryOptions} [options]
 */
export const useEnvConfig = (envConfigId, options) => {
  const client = useClient();
  return useFirestoreDoc(
    /** @type {firebase.default.firestore.DocumentReference<import('lib/envConfig/envConfig').EnvConfig>} */ (useEnvConfig.getKey(
      client.getId(),
      envConfigId
    )),
    {
      select: initConfig,
      ...options,
    }
  );
};

/**
 *
 * @param {string} clientId
 * @param {string} [envConfigId]
 */
useEnvConfig.getKey = (clientId, envConfigId) =>
  db.doc(
    `clients/${clientId}/envConfigs/${envConfigId || 'foo'}` // using foo otherwise Firebase complains that there are only 3 path parts
  );
