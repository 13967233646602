import React from 'react';
import { TextField, Grid } from '@mui/material';
import { Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Dialog } from 'components/Dialog/Dialog';
import { useUI } from 'components/UIContext/uiHooks';

/**
 * @param {Object} props
 * @param {import('lib/client/clientEnv').ClientEnv} props.clientEnv
 */
export const NewAccessDialog = (props) => {
  const access = props.clientEnv.getAccess();
  const defaultValues = {
    access: access || '',
  };
  const { closeDialog } = useUI();
  const form = useForm({ defaultValues });
  const [isRemoving, setIsRemoving] = React.useState(false);

  /** @param {typeof defaultValues} values */
  const onSubmit = async (values) => {
    await props.clientEnv.update((clientEnvDraft) => {
      clientEnvDraft.access = values.access;
    });
    closeDialog();
  };

  const onRemove = async () => {
    await props.clientEnv.update((clientEnvDraft) => {
      delete clientEnvDraft.access;
    });
    closeDialog();
  };

  return (
    <Dialog
      title={isRemoving ? 'Remove' : access ? 'Editar Acceso' : 'Crear acceso'}
      primaryButton={
        isRemoving
          ? () => <Dialog.ButtonRemoveConfirm onClick={onRemove} variant="contained" />
          : () => (
              <Button onClick={form.handleSubmit(onSubmit)} variant={'contained'} color={'primary'}>
                Guardar
              </Button>
            )
      }
      {...(access && {
        secondaryButton: isRemoving
          ? () => <Dialog.ButtonRemoveCancel onClick={() => setIsRemoving(false)} />
          : () => <Button onClick={() => setIsRemoving(true)}>Eliminar</Button>,
      })}
    >
      <Grid container spacing={3} direction="column">
        {!isRemoving && (
          <Grid item xs>
            <Controller
              name="access"
              control={form.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Descripción"
                  multiline
                  rows={3}
                  fullWidth
                  placeholder="Pasos cómo conectarse al entorno"
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};
