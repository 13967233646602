import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AdminServiceMenu } from 'components/Administration/AdminServiceMenu';
import { ServiceTypeSelect } from 'components/Administration/ServiceTypeSelect';
import { OutlinedButton } from 'components/Button/OutlinedButton';
import { Content } from 'components/Content/Content';
import { NewServiceDialog } from 'components/Services/NewServiceDialog';
import { useServices } from 'components/Services/servicesHooks';
import { useUI } from 'components/UIContext/uiHooks';

/**
 *
 * @param {Object} props
 * @param {JSX.Element|JSX.Element[]|string} [props.children]
 */
export const ServicesAdmin = (props) => {
  const services = useServices();
  const { openDialog } = useUI();
  const theme = useTheme();
  const [editRowsModel, setEditRowsModel] = React.useState(
    /** @type {import('@mui/x-data-grid').GridEditRowsModel} */ ({})
  );

  const validate = React.useCallback(
    (/**  @type {import('@mui/x-data-grid').GridEditCellPropsParams} */ { id, field, props }) => {
      let isValid = true;
      if (field === 'name') {
        if (!props.value) {
          isValid = false;
        }
        /** @type {Object<string, Object>} */
        const newState = {};
        newState[id] = {
          ...(editRowsModel[id] || {}),
          name: { ...props, error: !isValid },
        };
        setEditRowsModel((state) => ({ ...state, ...newState }));
      }
    },
    [editRowsModel]
  );

  const onChange = React.useCallback(
    ({ id, field, props }) => {
      services.data[id].update((serviceDraft) => {
        if (field === 'name') {
          serviceDraft.name = props.value;
        }
        if (field === 'type') {
          serviceDraft.type = props.value;
        }
      });
    },
    [services.data]
  );

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      editable: true,
      // width: 200,
      /** @param {import('@mui/x-data-grid').GridCellParams} editProps */
      renderEditCell: (editProps) => (
        <ServiceTypeSelect editProps={editProps} service={services.data[editProps.id]} onChange={onChange} />
      ),
      /** @param {import('@mui/x-data-grid').GridCellParams} params */
      renderCell: (params) => <Typography>{services.data[params.id].getType()}</Typography>,
    },
    {
      field: 'menu',
      headerName: '',
      width: 64,
      align: 'center',
      /** @param {import('@mui/x-data-grid').GridCellParams} params */
      renderCell: (params) => <AdminServiceMenu editProps={params} service={services.data[params.id]} />,
    },
  ];

  const stopEventPropagation = React.useCallback((params, event) => {
    if (params.field === 'type') {
      event.stopPropagation();
    }
  }, []);

  return (
    <Content fitWindow>
      <Box marginTop={2} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" marginBottom={1}>
          <Typography variant="h6">Servicios</Typography>
          <OutlinedButton
            onClick={() => {
              openDialog(() => <NewServiceDialog />);
            }}
          >
            <AddIcon fontSize="small" sx={{ marginRight: theme.spacing(1) }} />
            Nuevo servicio
          </OutlinedButton>
        </Box>
        <DataGrid
          autoHeight
          rows={Object.values(services.data).map((service) => service.getDto())}
          columns={columns}
          onEditCellChangeCommitted={onChange}
          onEditCellChange={validate}
          editRowsModel={editRowsModel}
          pageSize={10}
          onCellBlur={stopEventPropagation}
        />
      </Box>
    </Content>
  );
};
