import React from 'react';
import { useParams } from 'react-router';
import { useImmer } from 'use-immer';
import { ProductsContext } from 'components/Products/ProductsContext';
import { ProductNotFoundError } from 'lib/error/error';
import { initProducts } from 'lib/product/product';

export const useProducts = () => {
  const productsContext = React.useContext(ProductsContext);
  if (!productsContext.products) {
    throw new Error(`Missing ProductsProvider`);
  }
  const productsDtoQuery = productsContext.products;
  const productsMap = initProducts(productsDtoQuery.data);
  const [productsQuery, updateProductsQuery] = useImmer({
    ...productsDtoQuery,
    data: productsMap,
  });
  React.useEffect(() => {
    updateProductsQuery(() => {
      return {
        ...productsDtoQuery,
        data: initProducts(productsDtoQuery.data),
      };
    });
  }, [productsDtoQuery, updateProductsQuery]);

  return productsQuery;
};

/**
 * @param {string} [productId]
 */
export const useProduct = (productId) => {
  /** @type {{productId?: string}} */
  const urlParams = useParams();
  const products = useProducts();
  if (products.isFetching) {
    return Object.values(products.data)[0];
  }
  const id = productId || urlParams.productId;
  if (!id || !products.data[id]) {
    throw new ProductNotFoundError();
  }
  return products.data[id];
};
