import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Dialog } from 'components/Dialog/Dialog';
import { useServices } from 'components/Services/servicesHooks';
import { useUI } from 'components/UIContext/uiHooks';

/**
 *
 * @param {Object} props
 * @param {import('lib/client/client').Client} props.client
 * @param {string} props.envId
 */
export const AddClientServiceDialog = (props) => {
  const { closeDialog } = useUI();
  const services = useServices();
  const clientEnv = props.client.getEnv(props.envId);
  const form = useForm({
    defaultValues: {
      serviceId: '',
    },
  });
  const availableServices = Object.values(services.data || []).filter(
    (service) => !clientEnv.getServices()[service.getId()]
  );

  /**
   * @param {{serviceId: string}} serviceId
   */
  const addService = async ({ serviceId }) => {
    await clientEnv.addService(serviceId, props.client);
    closeDialog();
  };

  return (
    <Dialog
      title={`Add service to ${props.client.getName()} ${clientEnv.getName()}`}
      primaryButton={() => (
        <Button
          onClick={form.handleSubmit(addService)}
          variant={'contained'}
          color={'primary'}
          disabled={availableServices.length === 0}
        >
          Add
        </Button>
      )}
    >
      <Controller
        name="serviceId"
        control={form.control}
        rules={{ required: true }}
        render={({ field, formState }) => (
          <TextField
            {...field}
            label="Service"
            margin="dense"
            variant="outlined"
            disabled={availableServices.length === 0}
            error={availableServices.length === 0 || !!formState.errors.serviceId}
            helperText={availableServices.length === 0 && 'No more services available'}
            fullWidth
            select
          >
            {availableServices.map((service) => (
              <MenuItem value={service.getId()} key={service.getId()}>
                {service.getName()}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </Dialog>
  );
};
