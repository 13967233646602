import React from 'react';
import { Button, Grid, Link, Typography, useTheme } from '@mui/material';
// @ts-ignore
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { useClient, useClientEnv, useIsLoading } from 'components/Clients/clientHooks';
import { ConfigEditor } from 'components/ConfigEditor/ConfigEditor';
import { ConfigVersion } from 'components/ConfigEditor/ConfigVersion';
import { SkeletonCustom } from 'components/SkeletonCustom/SkeletonCustom';
import { useEnvConfig } from 'hooks/envConfigHooks';
import { getQueryDocKey } from 'hooks/firebaseHooks';
import { createEnvConfig } from 'lib/envConfig/envConfig';
import { useAuth } from 'lib/firebase/FirebaseAuthProvider';
import { deploy as deployTomcat } from 'lib/tomcat/tomcat';

export const ServiceCustomData = () => {
  /** @type {{clientId: string}}*/
  const { clientId } = useParams();
  const editorRef = React.useRef({ jsonEditor: { set: (/** @type {any} */ value) => {}, get: () => ({}) } });
  const client = useClient(clientId);
  const theme = useTheme();
  const queryClient = useQueryClient();
  const isLoading = useIsLoading();
  const [version, setVersion] = React.useState('');
  const clientEnv = useClientEnv();
  const envConfigId = clientEnv.getEnvConfigId();
  const { user } = useAuth();
  const tomcat = clientEnv.getTomcat();
  const { enqueueSnackbar } = useSnackbar();
  const envConfigQuery = useEnvConfig(envConfigId, { enabled: !!envConfigId && !isLoading });

  React.useEffect(() => {
    // Change historyc version
    if (isLoading) return;
    setVersion(version);
  }, [isLoading, version]);

  React.useEffect(() => {
    // Change env
    if (isLoading) return;
    setVersion(envConfigId);
  }, [isLoading, envConfigId]);

  const save = async () => {
    /** @type {import('@snc/emi-config').Config} */
    const configDto = editorRef?.current?.jsonEditor.get();
    const config = createEnvConfig(configDto);
    const newConfigDto = await config.saveConfig(clientEnv, user);
    queryClient.setQueryData(getQueryDocKey(useEnvConfig.getKey(client.getId(), newConfigDto.id)), newConfigDto);
    setVersion(newConfigDto.id);
    await client.update((clientDtoDraft) => {
      if (clientDtoDraft.envs) {
        clientDtoDraft.envs[clientEnv.getId()].envConfigId = newConfigDto.id;
      }
    });
  };

  const generateWar = async () => {
    const configDto = editorRef?.current?.jsonEditor.get();
    const config = createEnvConfig(configDto);
    const content = await config.generate();
    return config.createWar(content);
  };

  const download = async () => {
    await save();
    const war = await generateWar();
    saveAs(war, 'emi-suite-config.war');
  };

  const deployWar = async () => {
    if (!tomcat) return;
    await save();
    const war = await generateWar();
    try {
      await deployTomcat(war, tomcat.url, { user: tomcat.managerUser, password: tomcat.managerPassword });
      enqueueSnackbar('Config desplegado', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('No se ha podido desplegar el config', { variant: 'error' });
    }
  };

  return (
    <>
      <ConfigVersion version={version} setVersion={setVersion} />
      <ConfigEditor envConfigId={version} editorProps={{ mode: 'code' }} ref={editorRef} />

      <Grid container justifyContent="space-between" style={{ marginTop: theme.spacing(2) }}>
        <Grid item xs={12} sm={9}>
          <Typography style={{ fontWeight: theme.typography.fontWeightBold }}>Save</Typography>
          <Typography variant="body2" color="textSecondary">
            Only saves the config to DB.
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto" style={{ alignSelf: 'center' }}>
          <Button onClick={save} variant="outlined" fullWidth>
            Save
          </Button>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" style={{ marginTop: theme.spacing(2) }}>
        <Grid item xs={12} sm={9}>
          <Typography style={{ fontWeight: theme.typography.fontWeightBold }}>Save & Download WAR</Typography>
          <Typography variant="body2" color="textSecondary">
            Saves the config and generates emi-suite-config.war from this config. It will include the "signature" field
            with hash of the config's content.
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto" style={{ alignSelf: 'center' }}>
          <Button onClick={download} variant="outlined" style={{ whiteSpace: 'nowrap' }} fullWidth>
            Save & Download
          </Button>
        </Grid>
      </Grid>

      <SkeletonCustom isActive={envConfigQuery.isLoading}>
        <Grid container justifyContent="space-between" style={{ marginTop: theme.spacing(2) }}>
          <Grid item xs={12} sm={9}>
            <Typography style={{ fontWeight: theme.typography.fontWeightBold }}>Save & Deploy</Typography>
            <Typography variant="body2" color="textSecondary">
              Guarda el config y le despliega en{' '}
              <Link
                href={`${
                  envConfigQuery.data && envConfigQuery.data.getContent().API_BASE_URI
                }/emi-suite-config/config.json`}
                target="_blank"
              >
                {`${envConfigQuery.data && envConfigQuery.data.getContent().API_BASE_URI}/emi-suite-config/config.json`}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto" style={{ alignSelf: 'center' }}>
            <Button
              onClick={deployWar}
              variant="outlined"
              style={{ whiteSpace: 'nowrap' }}
              fullWidth
              disabled={!tomcat}
            >
              Save & Deploy
            </Button>
          </Grid>
        </Grid>
      </SkeletonCustom>
      {/* <Grid container justify="space-between" style={{ marginTop: theme.spacing(2) }}>
      <Grid item xs={12} sm={9}>
        <Typography style={{ fontWeight: theme.typography.fontWeightBold }} variant="h6">
          Deploy
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Saves the config to the DB and deploys it to the Tomcat
        </Typography>
      </Grid>
      <Grid item xs={12} sm="auto" style={{ alignSelf: 'center' }}>
        <Button onClick={() => deploy.mutate()} variant="outlined" fullWidth>
          Deploy
        </Button>
      </Grid>
    </Grid> */}
    </>
  );
};
