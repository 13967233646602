import React from 'react';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useClient } from 'components/Clients/clientHooks';
import { useService } from 'components/Services/servicesHooks';
import { getRoute, routes } from 'lib/routes';

/**
 *
 * @param {Object} props
 * @param {JSX.Element|JSX.Element[]|string} [props.children]
 */
export const ServiceBreadcrumbs = (props) => {
  const client = useClient();
  const service = useService();
  return (
    <Breadcrumbs>
      <Breadcrumbs.Item to={getRoute(routes.clients, { clientId: '' })}>Clients</Breadcrumbs.Item>
      <Breadcrumbs.Item to={getRoute(routes.clientDetail, { clientId: client.getId(), envId: '', envSection: '' })}>
        {client.getName()}
      </Breadcrumbs.Item>
      {/* <Breadcrumbs.Item to={getRoute(routes.clients, { clientId: '' })}>Services</Breadcrumbs.Item> */}
      <Breadcrumbs.Item isLast>{service.getName()}</Breadcrumbs.Item>
    </Breadcrumbs>
  );
};
