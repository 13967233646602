import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ClientsProvider } from 'components/Clients/ClientsProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import { Header } from 'components/Header/Header';
import { ProductsProvider } from 'components/Products/ProductsContext';
import { Router } from 'components/Router/Router';
import { ServicesProvider } from 'components/Services/ServicesProvider';
import { ThemeProvider } from 'components/Theme/ThemeProvider';
import { UIProvider } from 'components/UIContext/UIContext';
import { FirebaseAuthProvider } from 'lib/firebase/FirebaseAuthProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

export const App = () => {
  React.useEffect(() => {
    document.getElementById('prerendered')?.remove();
  }, []);

  return (
    <ErrorBoundary>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <SnackbarProvider
              preventDuplicate
              autoHideDuration={2000}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <FirebaseAuthProvider>
                  <ProductsProvider>
                    <ClientsProvider>
                      <ServicesProvider>
                        <UIProvider>
                          <Header />
                          <Router />
                        </UIProvider>
                      </ServicesProvider>
                    </ClientsProvider>
                  </ProductsProvider>
                </FirebaseAuthProvider>
              </BrowserRouter>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
