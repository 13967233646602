import React from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { useIsLoading } from 'components/Clients/clientHooks';
import { SkeletonCustom } from 'components/SkeletonCustom/SkeletonCustom';

/**
 * @param {Object} props
 * @param {ReturnType<Item>[]} [props.children]
 */
export const Breadcrumbs = (props) => {
  const isLoading = useIsLoading();
  return (
    <SkeletonCustom isActive={isLoading}>
      <Box display="flex" alignItems="center" component="nav">
        {/* <Item text={<HomeIcon classes={classes} />} onClick={() => setDescendant()} isLast={descendants.length === 0} /> */}
        {props.children}
      </Box>
    </SkeletonCustom>
  );
};

/**
 * @param {Object} props
 * @param {boolean} [props.isLast]
 * @param {string} [props.to]
 * @param {string} [props.children]
 */
const Item = (props) => {
  const theme = useTheme();

  return (
    <>
      {props.to ? (
        <Link to={props.to} component={LinkRouter} style={{ color: theme.palette.grey[700] }}>
          {props.children}
        </Link>
      ) : (
        <Typography
          variant="body2"
          style={{
            color: theme.palette.grey[700],
            ...(props.isLast && { fontWeight: theme.typography.fontWeightBold }),
          }}
        >
          {props.children}
        </Typography>
      )}
      {!props.isLast && (
        <Typography
          component="span"
          style={{
            color: theme.palette.grey[700],
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            cursor: 'pointer',
          }}
        >
          &#62;
        </Typography>
      )}
    </>
  );
};

Breadcrumbs.Item = Item;
