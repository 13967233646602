import React from 'react';
import { duration } from '@mui/material/styles';

/**
 * @typedef {any} UIContext
 */
/** @type {UIContext} */
export const UIContext = React.createContext({});

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 */
export const UIProvider = (props) => {
  const [dialog, setDialog] = React.useState(/** @type {undefined|Function} */ (undefined));
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [contentHeight, setContentHeight] = React.useState(/** @type {undefined|number} */ (undefined));

  const openDialog = React.useCallback((fn) => {
    setDialog(() => fn);
  }, []);

  const closeDialog = React.useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  // On open
  React.useEffect(() => {
    if (dialog) {
      setIsDialogOpen(true);
    }
  }, [dialog]);

  // On close
  React.useEffect(() => {
    if (!isDialogOpen) {
      setTimeout(() => {
        setDialog(undefined);
      }, duration.leavingScreen);
    }
  }, [openDialog, isDialogOpen]);

  return (
    <UIContext.Provider value={{ openDialog, closeDialog, isDialogOpen, setContentHeight, contentHeight }}>
      {props.children}
      {dialog?.()}
    </UIContext.Provider>
  );
};
