import React from 'react';
import { TextField, Grid } from '@mui/material';
import { Button } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Controller, useForm } from 'react-hook-form';
import { Dialog } from 'components/Dialog/Dialog';
import { useUI } from 'components/UIContext/uiHooks';

/**
 * @param {Object} props
 * @param {import('lib/client/clientEnv').ClientEnv} props.clientEnv
 */
export const NewTomcatDialog = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const tomcat = props.clientEnv.getTomcat();
  const defaultValues = {
    url: tomcat?.url || '',
    managerUser: tomcat?.managerUser || '',
    managerPassword: tomcat?.managerPassword || '',
  };
  const { closeDialog } = useUI();
  const form = useForm({ defaultValues });
  const [isRemoving, setIsRemoving] = React.useState(false);

  const handleToggleHide = () => {
    setShowPassword((prev) => !prev);
  };

  /** @param {typeof defaultValues} values */
  const onSubmit = async (values) => {
    await props.clientEnv.update((clientEnvDraft) => {
      clientEnvDraft.tomcat = values;
    });
    closeDialog();
  };

  const onRemove = async () => {
    await props.clientEnv.update((clientEnvDraft) => {
      delete clientEnvDraft.tomcat;
    });
    closeDialog();
  };

  return (
    <Dialog
      title={isRemoving ? 'Eliminar' : tomcat ? 'Editar Tomcat' : 'Inicializar Tomcat'}
      primaryButton={
        isRemoving
          ? () => <Dialog.ButtonRemoveConfirm onClick={onRemove} variant="contained" />
          : () => (
              <Button onClick={form.handleSubmit(onSubmit)} variant={'contained'} color={'primary'}>
                Guardar
              </Button>
            )
      }
      {...(tomcat && {
        secondaryButton: isRemoving
          ? () => <Dialog.ButtonRemoveCancel onClick={() => setIsRemoving(false)} />
          : () => <Button onClick={() => setIsRemoving(true)}>Eliminar</Button>,
      })}
    >
      <Grid container spacing={3} direction="column">
        {!isRemoving && (
          <>
            <Grid item xs>
              <Controller
                name="url"
                control={form.control}
                rules={{ required: true }}
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    placeholder="Por ejemplo: https://emi.soincon.es"
                    label="Url"
                    error={!!formState.errors.url}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              <Controller
                name="managerUser"
                control={form.control}
                rules={{ required: true }}
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    placeholder="El nombre del usuario que puede acceder al Tomcat Manager"
                    label="Manager User"
                    error={!!formState.errors.managerUser}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              <Controller
                name="managerPassword"
                control={form.control}
                rules={{ required: true }}
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    label="Password"
                    error={!!formState.errors.managerPassword}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleHide} edge="end" size="large">
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  );
};
