import React from 'react';
import { Dialog as DialogMUI, DialogTitle, DialogContent, DialogActions, Button, Box, useTheme } from '@mui/material';
import { useUI } from 'components/UIContext/uiHooks';

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.title]
 * @param {Function} [props.primaryButton]
 * @param {Function} [props.secondaryButton]
 * @param {React.ReactNode} [props.children]
 */
const Dialog = (props) => {
  const { title, secondaryButton, primaryButton } = props;
  const { isDialogOpen, closeDialog } = useUI();
  // const { text: primaryButtonText, ...primaryButtonProps } = props.primaryButton || {};

  return (
    <DialogMUI fullWidth={true} maxWidth={'sm'} open={isDialogOpen} onClose={closeDialog} scroll={'paper'}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{props.children}</DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        {secondaryButton ? secondaryButton() : <Box />}
        {primaryButton?.()}
      </DialogActions>
    </DialogMUI>
  );
};

/**
 * @param {import('@mui/material').ButtonProps} props
 */
const ButtonRemoveConfirm = (props) => {
  const theme = useTheme();
  return (
    <Button {...props} style={{ backgroundColor: theme.palette.error.main, color: theme.palette.background.default }}>
      Yes
    </Button>
  );
};
/**
 * @param {import('@mui/material').ButtonProps} props
 */
Dialog.ButtonRemoveCancel = (props) => <Button {...props}>No</Button>;

Dialog.ButtonRemoveConfirm = ButtonRemoveConfirm;

export { Dialog };
