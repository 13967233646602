import React from 'react';
import { Box, Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 */
export default (props) => {
  return <ErrorBoundary FallbackComponent={(p) => <FallbackComponent {...p} />}>{props.children}</ErrorBoundary>;
};

/**
 * @param {import('react-error-boundary').FallbackProps} props
 */
const FallbackComponent = (props) => {
  return (
    <Box
      width={1}
      height={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: 'calc(100vh)' }}
    >
      <Typography variant="h5">Something went wrong</Typography>
    </Box>
  );
};
