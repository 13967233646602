import React from 'react';
import { Box, Link } from '@mui/material';
import { Divider } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { getRoute, routes } from 'lib/routes';

/**
 * @param {Object} props
 * @param {import('lib/client/client').Client} props.client
 */
export const ClientStatus = (props) => {
  return (
    <Box marginBottom={1}>
      <Box padding={1} display="flex" alignItems="center">
        <Link
          variant="body2"
          component={LinkRouter}
          to={getRoute(routes.clientDetail, { clientId: props.client.getId(), envId: '', envSection: '' })}
          color="inherit"
          sx={{
            textDecoration: 'none',
            width: '100%',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {props.client.getName()}
        </Link>
      </Box>

      <Divider />
    </Box>
  );
};
