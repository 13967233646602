import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomTabs = styled(Tabs)({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
});

export const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 72,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(2),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&$selected': {
    color: '#1890ff',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&:focus': {
    color: '#40a9ff',
  },
}));
