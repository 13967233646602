import trimEnd from 'lodash/trimEnd';
/**
 *
 * @param {import("lib/envConfig/envConfig").War} war
 * @param {string} url
 * @param {{user: string, password: string}} credentials
 */
export const deploy = async (war, url, credentials) => {
  const requestOptions = {
    method: 'PUT',
    body: war,
    headers: {
      Authorization: 'Basic ' + btoa(`${credentials.user}:${credentials.password}`),
    },
  };

  return fetch(`${trimEnd(url, '/')}/manager/text/deploy?path=/emi-suite-config&update=true`, requestOptions);
};
