import React from 'react';
import { useFirestoreCol } from 'hooks/firebaseHooks';
import { db } from 'lib/firebase/firebase';

/** @type {ServicesContext} */
const defaultContext = {
  services: null,
};
const servicesPlaceholders = [{ id: 'serviceId1' }, { id: 'id2' }];

export const ServicesContext = React.createContext(defaultContext);

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 */
export function ServicesProvider(props) {
  const services = useFirestoreCol(
    /** @type {firebase.default.firestore.CollectionReference<ServiceDto[]>} */ (db.collection('services')),
    { placeholderData: servicesPlaceholders }
  );
  return <ServicesContext.Provider value={{ services }}>{props.children}</ServicesContext.Provider>;
}

/**
 * @typedef {{
 *  services: import('react-query').UseQueryResult<ServiceDto[]>?
 * }} ServicesContext
 */
/** @typedef {import('lib/service/service').ServiceDto} ServiceDto */
