import { useQuery, useQueryClient } from 'react-query';

/**
 * @template Result
 * @param {(firebase.default.firestore.CollectionReference<Result> | firebase.default.firestore.Query<Result>)} query
 * @param {import('react-query').UseQueryOptions} [options]
 8 @returns {import('react-query').UseQueryResult<Result>}
 */
export const useFirestoreCol = (query, options) => {
  const key = [
    query._delegate._query.path,
    query._delegate._query.explicitOrderBy,
    query._delegate._query.filters,
    query._delegate._query.limit,
    query._delegate._query.limitType,
    query._delegate._query.startAt,
    query._delegate._query.endAt,
  ];
  const queryClient = useQueryClient();
  /** @type {import('react-query').UseQueryOptions} */
  const defaultOptions = {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  };
  return useQuery(
    key,
    () => {
      return new Promise((resolve) => {
        query.onSnapshot((snapshot) => {
          let result = /** @type {Result[]} */ ([]);
          if (snapshot.docs) {
            result = snapshot.docs.map((doc) => {
              return { ...doc.data(), id: doc.id };
            });
          }
          queryClient.setQueryData(key, result);
          resolve(result);
        });
      });
    },
    defaultOptions
  );
};

/**
 * @template Result
 * @param {firebase.default.firestore.DocumentReference<Result>} query
 * @param {import('react-query').UseQueryOptions} [options]
 8 @returns {import('react-query').UseQueryResult<Result>}
 */
export const useFirestoreDoc = (query, options) => {
  const key = getQueryDocKey(query);
  const queryClient = useQueryClient();
  /** @type {import('react-query').UseQueryOptions} */
  const defaultOptions = {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  };
  return useQuery(
    key,
    () => {
      return new Promise((resolve, reject) => {
        console.log('🛎 ', 'fetch', key);
        query.onSnapshot((snapshot) => {
          if (!snapshot.exists) return reject('Firebase document not found.');
          const result = { ...snapshot.data(), id: snapshot.id };
          queryClient.setQueryData(key, result);
          resolve(result);
        });
      });
    },
    defaultOptions
  );
};

/**
 *
 * @param {firebase.default.firestore.DocumentReference} queryDoc
 */
export const getQueryDocKey = (queryDoc) => {
  return [queryDoc._delegate._key];
};
