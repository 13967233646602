import firebase from 'firebase/app';
import { config } from 'lib/firebase/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { getCookie } from 'utils/browserUtils.js';

const env = process.env.REACT_APP_ENV || 'production';

const firebaseApp = firebase.initializeApp(config[env]);
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const functions = firebaseApp.functions();
if (
  (process.env.NODE_ENV === 'development' &&
    (!process.env.REACT_APP_DISABLE_EMULATORS || process.env.REACT_APP_DISABLE_EMULATORS === 'false')) ||
  getCookie('useEmulators')
) {
  db.settings({ experimentalForceLongPolling: true });
  functions.useEmulator('localhost', 5001);
  db.useEmulator(window.location.hostname, 8080);
  auth.useEmulator(`http://${window.location.hostname}:9099`);
}

export { db, auth, functions };
