import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// import { ClientProduct } from 'components/ClientProduct/ClientProduct';
import { ServicesAdmin } from 'components/Administration/ServicesAdmin';
import { ClientRoute } from 'components/Clients/ClientRoute';
import { Clients } from 'components/Clients/Clients';
import { ServiceRoute } from 'components/Services/ServiceRoute';
import { routes } from 'lib/routes';

export const Router = () => {
  return (
    <Switch>
      <Route path={routes.clients}>
        <Switch>
          {/* <Route path={routes.clientProductDetail}>
            <ClientProduct />
          </Route> */}
          <Route path={routes.clientService}>
            <ServiceRoute />
          </Route>
          <Route path={routes.clientDetail}>
            <ClientRoute />
          </Route>
          <Route path={routes.clients}>
            <Clients />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Route>
      <Route path={routes.services}>
        <ServicesAdmin />
      </Route>
      <Redirect to={routes.clients} />
    </Switch>
  );
};
