import React from 'react';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ServiceBreadcrumbs } from 'components/Breadcrumbs/ServiceBreadcrumbs';
import { ButtonSection } from 'components/Button/ButtonSection';
import { useClient } from 'components/Clients/clientHooks';
import { Content } from 'components/Content/Content';
import { RemoveConfirmDialog } from 'components/Dialog/RemoveConfirmDialog';
import { EnvSelect } from 'components/EnvSelect/EnvSelect';
import { ServiceCustomData } from 'components/Services/ServiceCustomData';
import { useService } from 'components/Services/servicesHooks';
import { ServiceWeb } from 'components/Services/ServiceWeb';
import { useUI } from 'components/UIContext/uiHooks';
import { getRoute, routes } from 'lib/routes';

export const ServiceRoute = () => {
  const urlParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  return (
    // TODO: ErrorBoundary is logging the exception to the console. Solution not implemented yet: https://github.com/facebook/react/issues/15069
    <ErrorBoundary
      fallbackRender={() => {
        setTimeout(() => {
          enqueueSnackbar('Please add the service first', { variant: 'error' });
        }, 10);
        return <Redirect to={getRoute(routes.clientDetail, urlParams)} />;
      }}
    >
      <ServicesRouteWithBoundary />
    </ErrorBoundary>
  );
};

const ServicesRouteWithBoundary = () => {
  /** @type {{envId: string}} */
  const { envId } = useParams();
  const service = useService();
  const theme = useTheme();
  const { openDialog, closeDialog } = useUI();
  const client = useClient();
  const history = useHistory();

  const remove = async () => {
    await client.update((draft) => {
      delete draft.envs?.[envId]?.services?.[service.getId()];
    });
    closeDialog();
    history.push(
      getRoute(routes.clientDetail, {
        clientId: client.getId(),
        envId,
        envSection: 'services',
      })
    );
  };

  return (
    <Content>
      <Box marginTop={1}>
        <ServiceBreadcrumbs />
      </Box>

      <Box display="flex" marginTop={2} justifyContent="space-between" alignItems="center">
        <Typography variant="h5" component="h2" style={{ fontWeight: theme.typography.fontWeightMedium }}>
          {service.getName()}
        </Typography>
      </Box>

      <EnvSelect envId={envId} />
      {/* <ClientTabs /> */}

      <Box marginTop={1} />

      {service.isCustomData() && <ServiceCustomData />}
      {service.isWeb() && <ServiceWeb />}

      <Divider style={{ marginTop: theme.spacing(2) }} />

      <ButtonSection
        title="Eliminar servicio"
        description="Elimina el servicio de la base de datos. Servicio va a seguir desplegado."
        titleStyle={{ color: theme.palette.error.dark }}
      >
        <Button
          variant="outlined"
          style={{ backgroundColor: theme.palette.error.dark, color: theme.palette.background.default }}
          onClick={() => openDialog(() => <RemoveConfirmDialog onConfirm={remove} resource={service.getName()} />)}
        >
          Remove service
        </Button>
      </ButtonSection>
    </Content>
  );
};
