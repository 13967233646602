/**
 * @param {ClientProductDto} clientProductDto
 * @param {ProductDto} [productDto]
 */
export const initClientProduct = (clientProductDto, productDto) => {
  const getId = () => clientProductDto.id;
  const getName = () => productDto?.name || '[Missing service name]';

  return { getId, getName };
};

/** @typedef {ReturnType<initClientProduct>} ClientProduct */

/**
 * @typedef {Object} ClientProductDto
 * @prop {string} id
 */

/** @typedef {import('lib/product/product').ProductDto} ProductDto */
