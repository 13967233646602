import React from 'react';
import { Box, Button, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Dialog } from 'components/Dialog/Dialog';
import { NewServiceDialog } from 'components/Services/NewServiceDialog';
import { useUI } from 'components/UIContext/uiHooks';

/**
 * @param {Object} props
 * @param {import('@material-ui/data-grid').GridCellParams} props.editProps
 * @param {import('lib/service/service').Service} props.service
 */
export const AdminServiceMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(/** @type {null | HTMLElement} */ (null));
  const { openDialog, closeDialog } = useUI();
  const theme = useTheme();

  const openMenu = (/** @type {React.MouseEvent<HTMLButtonElement>} */ event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const remove = async () => {
    await props.service.remove();
    closeDialog();
  };

  const openEdit = async () => {
    openDialog(() => <NewServiceDialog service={props.service} />);
    handleClose();
  };

  const handleRemoveClick = () => {
    openDialog(() => (
      <Dialog
        title={`Remove`}
        primaryButton={() => <Dialog.ButtonRemoveConfirm onClick={remove} variant="contained" />}
        secondaryButton={() => (
          <Button onClick={closeDialog} variant="outlined">
            Cancel
          </Button>
        )}
      >
        <Typography>
          Are you sure you want to remove{' '}
          <Typography component="strong" style={{ fontWeight: theme.typography.fontWeightBold }}>
            {props.service.getName()}
          </Typography>
          ?
        </Typography>
      </Dialog>
    ));
  };

  return (
    <Box>
      <Button onClick={openMenu}>
        <MoreVertIcon />
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={openEdit}>Edit</MenuItem>
        <MenuItem onClick={handleRemoveClick}>Remove</MenuItem>
      </Menu>
    </Box>
  );
};
