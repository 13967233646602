import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {Object} [props.titleStyle]
 * @param {JSX.Element|JSX.Element[]|string} [props.children]
 */
export const ButtonSection = (props) => {
  const theme = useTheme();
  return (
    <Grid container justifyContent="space-between" style={{ marginTop: theme.spacing(2) }}>
      <Grid item xs={12} sm={9}>
        <Typography style={{ fontWeight: theme.typography.fontWeightBold, ...(props.titleStyle || {}) }}>
          {props.title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {props.description}
        </Typography>
      </Grid>
      <Grid item xs={12} sm="auto" style={{ alignSelf: 'center' }}>
        {props.children}
      </Grid>
    </Grid>
  );
};
