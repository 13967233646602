import React from 'react';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Dialog } from 'components/Dialog/Dialog';
import { useUI } from 'components/UIContext/uiHooks';
import { createEnvDto, initClientEnv } from 'lib/client/clientEnv';

/**
 *
 * @param {Object} props
 * @param {import('lib/client/client').Client} props.client
 * @param {JSX.Element|JSX.Element[]|string} [props.children]
 * @param {(clientEnv: import('lib/client/clientEnv').ClientEnv) => any|void} [props.onCreate]
 */
export const NewEnvironmentDialog = (props) => {
  const { closeDialog } = useUI();
  const form = useForm({
    defaultValues: {
      name: '',
    },
  });

  /** @param {{name: string}} values */
  const createEnv = async (values) => {
    const envDto = createEnvDto(values);
    const env = initClientEnv(envDto, { client: props.client });
    await props.client.update((clientDtoDraft) => {
      clientDtoDraft.envs = {
        ...(clientDtoDraft.envs || {}),
        [env.getId()]: env.getDto(),
      };
    });
    closeDialog();
    props.onCreate?.(env);
  };

  return (
    <Dialog
      title="Create Environment"
      primaryButton={() => (
        <Button onClick={form.handleSubmit(createEnv)} variant={'contained'} color={'primary'}>
          Create
        </Button>
      )}
    >
      <Controller
        name="name"
        control={form.control}
        rules={{ required: true, validate: (value) => !envExists(props.client, value) || 'Environment already exists' }}
        render={({ field, formState }) => (
          <TextField
            {...field}
            label="Environment"
            error={!!formState.errors.name}
            helperText={formState.errors.name?.message}
          />
        )}
      />
    </Dialog>
  );
};

/**
 * @param {import('lib/client/client').Client} client
 * @param {string} name
 */
const envExists = (client, name) => {
  const { id } = createEnvDto({ name });
  try {
    client.getEnv(id);
    return true;
  } catch (e) {
    return false;
  }
};
