import React from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import { Dialog } from 'components/Dialog/Dialog';
import { useUI } from 'components/UIContext/uiHooks';

/**
 * @param {Object} props
 * @param {() => void} props.onConfirm
 * @param {string} props.resource
 */
export const RemoveConfirmDialog = (props) => {
  const theme = useTheme();
  const { closeDialog } = useUI();

  return (
    <Dialog
      title={`Remove`}
      primaryButton={() => <Dialog.ButtonRemoveConfirm onClick={props.onConfirm} variant="contained" />}
      secondaryButton={() => (
        <Button onClick={closeDialog} variant="outlined">
          Cancel
        </Button>
      )}
    >
      <Typography>
        Are you sure you want to remove{' '}
        <Typography component="strong" style={{ fontWeight: theme.typography.fontWeightBold }}>
          {props.resource}
        </Typography>
        ?
      </Typography>
    </Dialog>
  );
};
