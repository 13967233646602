import { set } from 'lodash';
import slugify from 'slugify';
import { initClientProduct } from 'lib/client/clientProduct';
import { initClientService } from 'lib/client/clientService';
import { ServiceNotFoundError } from 'lib/error/error';

/**
 * @param {ClientEnvDto} clientEnvDto
 * @param {{
 *  client: Client
 *  servicesDto?: Object<string, ServiceDto>
 *  allProducts?: Object<string, ProductDto>
 * }} clientEnvDto
 */
export const initClientEnv = (clientEnvDto, { client, servicesDto, allProducts }) => {
  const getId = () => clientEnvDto.id;
  const getName = () => clientEnvDto?.name || '[Missing env name]';
  const getEnvConfigId = () => clientEnvDto?.envConfigId || '';
  const getActivityLog = () => clientEnvDto.activityLog;
  const getTomcat = () => clientEnvDto.tomcat;

  const getServices = () => {
    const services = clientEnvDto.services || {};
    return Object.entries(services).reduce((result, [serviceId, clientServiceDto]) => {
      return {
        ...result,
        [serviceId]: initClientService(
          { ...clientServiceDto, id: serviceId },
          {
            serviceDto: servicesDto?.[serviceId],
            clientEnv: self,
          }
        ),
      };
    }, /** @type {{[id: string]: import('lib/client/clientService').ClientService}} */ ({}));
  };

  /**
   * @param {string} serviceId
   */
  const getService = (serviceId) => {
    const services = getServices();
    const service = services[serviceId];
    if (!service) {
      throw new ServiceNotFoundError();
    }
    return service;
  };

  /**
   * @param {string} serviceId
   * @param {import('lib/client/client').Client} client
   */
  const addService = async (serviceId, client) => {
    return client.update((clientDtoDraft) => {
      set(clientDtoDraft, ['envs', getId(), 'services', serviceId], {});
    });
  };

  const getProducts = () => {
    const products = clientEnvDto.products || {};
    return Object.entries(products).reduce((result, [productId, clientProductDto]) => {
      return {
        ...result,
        [productId]: initClientProduct({ ...clientProductDto, id: productId }, allProducts?.[productId]),
      };
    }, /** @type {{[id: string]: ClientProduct}} */ ({}));
  };

  const getDto = () => ({
    id: getId(),
    name: getName(),
  });

  const getAccess = () => clientEnvDto.access;
  const getClient = () => client;

  /**
   * @param {(draft: ClientEnvDto) => void} produceFn
   */
  const update = (produceFn) => {
    return client.update((clientDraftDto) => {
      const clientEnvDraft = clientDraftDto.envs?.[getId()];
      if (!clientEnvDraft) throw new Error('Env not editable');
      produceFn(clientEnvDraft);
    });
  };

  const self = {
    getId,
    getServices,
    getService,
    addService,
    getProducts,
    getName,
    getDto,
    getEnvConfigId,
    getClient,
    getAccess,
    getActivityLog,
    getTomcat,
    update,
  };

  return self;
};

/** @param {{name: string}} values */
export const createEnvDto = (values) => {
  return {
    ...values,
    id: slugify(values.name, {
      lower: true,
      remove: /[*+~.()'"!:@]/g,
    }),
  };
};

/** @typedef {ReturnType<initClientEnv>} ClientEnv */

/**
 * @typedef {Object} ClientEnvDto
 * @prop {string} id
 * @prop {string} [name]
 * @prop {string} [envConfigId]
 * @prop {Object<string, ClientProductDto>} [products]
 * @prop {Object<string, ClientServiceDto>} [services]
 * @prop {string} [access]
 * @prop {string} [activityLog]
 * @prop {{url: string, managerUser: string, managerPassword: string}} [tomcat]
 */

/** @typedef {import('lib/product/product').Product} Product */
/** @typedef {import('lib/service/service').ServiceDto} ServiceDto */
/** @typedef {import('lib/client/client').Client} Client */
/** @typedef {import('lib/product/product').ProductDto} ProductDto */
/** @typedef {{[id: string]: import('lib/product/product').Product}} ClientProductsMap */
/** @typedef {import('lib/client/clientService').ClientServiceDto} ClientServiceDto */
/** @typedef {import('lib/client/clientProduct').ClientProductDto} ClientProductDto */
/** @typedef {import('lib/client/clientProduct').ClientProduct} ClientProduct */
