import React from 'react';
import { ClickAwayListener, Select } from '@mui/material';

/**
 *
 * @param {Object} props
 * @param {import('@material-ui/data-grid').GridCellParams} props.editProps
 * @param {import('lib/service/service').Service} props.service
 * @param {Function} props.onChange
 * @param {JSX.Element|JSX.Element[]|string} [props.children]
 */
export const ServiceTypeSelect = (props) => {
  /**
   *
   * @param {string} value
   */
  const onChange = (value) => {
    props.onChange({
      id: props.service.getId(),
      field: 'type',
      props: { value },
    });
    finishEdit();
  };

  const finishEdit = () => {
    props.editProps.api.setCellMode(props.editProps.id, props.editProps.field, 'view');
  };

  return (
    <ClickAwayListener onClickAway={finishEdit}>
      <Select
        value={props.service.getType()}
        onChange={(e) => onChange(/** @type {string} */ (e.target.value))}
        onClose={() => finishEdit()}
        inputProps={{ style: { height: '100%' } }}
        native
      >
        <option value="web">Web</option>
        <option value="api">API</option>
        <option value="customData">Custom data</option>
      </Select>
    </ClickAwayListener>
  );
};
