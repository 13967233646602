import React from 'react';
import { TextField, Grid } from '@mui/material';
import { Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useClients } from 'components/Clients/clientHooks';
import { Dialog } from 'components/Dialog/Dialog';
import { useUI } from 'components/UIContext/uiHooks';
import { createClient } from 'lib/client/client';

/**
 *
 * @param {Object} props
 * @param {import('lib/client/client').Client} [props.client]
 */
export const NewClientDialog = (props) => {
  const { closeDialog } = useUI();
  const clients = useClients();
  const defaultValues = {
    name: props.client ? props.client.getName() : '',
    description: props.client ? props.client.getDescription() : '',
  };
  const form = useForm({ defaultValues });

  /** @param {typeof defaultValues} values */
  const onSubmit = async (values) => {
    if (props.client) {
      await props.client.update((draft) => {
        draft.name = values.name;
        draft.description = values.description;
      });
    } else {
      const newClient = createClient(values);
      await newClient.save();
    }
    closeDialog();
  };

  return (
    <Dialog
      title={`${props.client ? 'Edit' : 'Create'} Client`}
      primaryButton={() => (
        <Button onClick={form.handleSubmit(onSubmit)} variant={'contained'} color={'primary'}>
          {props.client ? 'Update' : 'Create'}
        </Button>
      )}
    >
      <Grid container direction="column">
        <Grid item xs>
          <Controller
            name="name"
            control={form.control}
            rules={{
              required: true,
              validate: (name) => !clientExists(clients.data, name, props.client) || 'Client already exists',
            }}
            render={({ field, formState }) => (
              <TextField
                {...field}
                label="Name"
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <Controller
            name="description"
            control={form.control}
            render={({ field, formState }) => (
              <TextField
                {...field}
                label="Description"
                multiline
                rows={3}
                fullWidth
                placeholder="Opcional info sobre contactos en la empresa, reglas de despliegues, desarrolladores, ..."
              />
            )}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

/**
 * @param {import('lib/client/client').Clients} clients
 * @param {string} name
 * @param {import('lib/client/client').Client} [editingClient]
 */
const clientExists = (clients, name, editingClient) => {
  const { id } = createClient({ name });
  if (editingClient && editingClient.getId() === id) return false;
  return !!clients[id];
};
