import React from 'react';
import { GlobalStyles, useTheme } from '@mui/material';
// @ts-ignore
import { JsonEditor } from 'jsoneditor-react';
import { useIsLoading } from 'components/Clients/clientHooks';
import { SkeletonCustom } from 'components/SkeletonCustom/SkeletonCustom';
import { useEnvConfig } from 'hooks/envConfigHooks';

export const ConfigEditor = React.forwardRef(
  /**
   * @param {Object} props
   * @param {string} [props.envConfigId]
   * @param {Object} [props.editorProps]
   */
  (props, ref) => {
    const editorRef = React.useRef({ jsonEditor: { set: (/** @type {any} */ value) => {}, get: () => ({}) } });
    const isLoading = useIsLoading();
    const theme = useTheme();
    const envConfigQuery = useEnvConfig(props.envConfigId, {
      enabled: !!props.envConfigId && !isLoading,
    });

    React.useEffect(() => {
      if (!props.envConfigId) {
        editorRef.current?.jsonEditor.set({});
        return;
      }
      if (!envConfigQuery.data) return;
      editorRef.current?.jsonEditor.set(envConfigQuery.data.getContent());
    }, [ref, envConfigQuery, props.envConfigId]);

    const editor = editorRef.current;
    React.useEffect(() => {
      if (!ref) return;
      ref.current = editor;
    }, [ref, editor]);

    return (
      <>
        <SkeletonCustom isActive={envConfigQuery.isLoading || isLoading}>
          <JsonEditor
            value={{}}
            mainMenuBar={false}
            ref={editorRef}
            mode="code"
            htmlElementProps={{ style: { height: 400, width: '100%' } }}
            {...(props.editorProps || {})}
          />
        </SkeletonCustom>
        <GlobalStyles
          styles={{
            '.jsoneditor': {
              borderColor: 'rgba(0, 0, 0, 0.23) !important', // Same as border of outlined button
              borderRadius: theme.shape.borderRadius,
            },
          }}
        />
      </>
    );
  }
);
