import { functions } from './firebase.js';

/**
 * @param {string} ldapUsername
 */
export const sendLoginEmail = async (ldapUsername) => {
  const sendLoginEmail = functions.httpsCallable('sendLoginEmail');
  const { data } = await sendLoginEmail({ ldapUsername, ref: window.location.href });

  if (data.error) {
    throw new Error(data.error);
  }
  return data;
};
